import { Draggable } from "react-beautiful-dnd";
import { useEffect } from 'react';
import { draggableStyle } from '../../styles/draggableStyle';
import { useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignVncLoading } from 'app/view';
import { ProgressBar } from "react-step-progress-bar";
import TileHeader from 'aesthetic/TileHeader';
import VNCTileIframe from "./VNCTileIframe";
import VNCTileIframeTest2 from "./VNCTileIframeTest2";

export default function VNCTile(props) {
   const dispatch = useDispatch()
   const fullScreen = useSelector(state => state.view.fullScreen)
   const timer = 50000
   const vncLoading = useSelector(state => state.view.vncLoading)
   const [loading, setLoading] = useState(false)
   const [editing, setEditing] = useState(false)
   const editingRef = useRef(editing)
   const propsRef = useRef(props)
   // let rfb;
   let loadingInterval

   const checkProgress = () => {
      let initTime = vncLoading[props.id].initTime
      let currTime = new Date().getTime()
      let diff = currTime - initTime
      if (diff >= timer) {
         setLoading(false)
         dispatch(assignVncLoading({
            id: props.id,
            done: true,
            initTime: vncLoading[props.id].initTime,
            progress: 1,
         }))

         clearInterval(loadingInterval)
      } else {
         setLoading(true)
         let progress = diff / timer
         dispatch(assignVncLoading({
            id: props.id,
            done: false,
            initTime: vncLoading[props.id].initTime,
            progress: progress,
         }))
      }
   }

   useEffect(() => {
      editingRef.current = editing
      propsRef.current = props
   })


   useEffect(() => {
      // set refresh interval to random # between 750 and 1000
      let refreshRate = Math.floor(Math.random() * 250) + 750
      // if the VNC tile is in the loading object
      if (vncLoading[props.id] !== undefined) {
         // and if it's not done loading
         if (!vncLoading[props.id].done) loadingInterval = setInterval(checkProgress, refreshRate)
      } else {
         setLoading(false)
      }

      return () => {
         clearInterval(loadingInterval)
      }
   }, [])



   const handleMouseEnter = (e) => {
      try {
         let el = document.getElementById(props.id)
         if (!props.large) el.firstChild.style.opacity = 1
      } catch (error) {
      }
   }

   const handleMouseLeave = (e) => {
      try {
         let el = document.getElementById(props.id)
         if (!props.large) el.firstChild.style.opacity = 0
      } catch (error) {
      }
   }

   const iframeId = props.id;
   const iframeRef = useRef(null);
   console.log(iframeRef);
   function test() {
      console.log('test');
   }

   function log(msg, type = 'info') {
      const logStyles = {
         info: `background:black;color:#87ceeb;border:1px solid #87ceeb;font-size:18px;padding:10px;`,
         error: `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`,
      }
      console.log(`%c${msg}`, logStyles[type]);
   }

   useEffect(() => {
      if (!iframeRef.current) return;
      console.clear(); // temp!
      const iframe = iframeRef.current;
      console.log('iframeRef', iframeRef);
      console.log('iframeeeee', iframe);
      // const iframeBody = iframe.contentDocument.body;
      const iframeDocument = iframe.contentDocument;
      console.log('iframeDocument', iframeDocument);
      if (!iframeDocument) {
         log('iframeDocument is null', 'error');
         return;
      }
      const iframeBody = iframe.contentWindow.document.body;
      console.log('iframeBody', iframeBody);

      const onMouseMove = (e) => {
         console.warn('ss');
      }

      iframeDocument.addEventListener('mousemove', onMouseMove);

      return () => {
         iframeDocument.removeEventListener('click', onMouseMove);
      }

   }, []);

   let progress = 0
   try {
      progress = vncLoading[props.id].progress * 100
   } catch (error) {
   }
   if (loading) {
      return (
         <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
            {(provided, snapshot) => (
               <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="loadTile flex-container-column"
                  style={draggableStyle(snapshot, provided.draggableProps.style, props.id)}
                  id={props.id}
               >
                  <TileHeader title={props.item.title} id={props.id} accessLevel={props.accessLevel} {...props} />
                  <div className="imgWrapper">
                     <div className="progressWrapper">
                        <ProgressBar className="vnc-progress" percent={progress} />
                     </div>
                  </div>
               </div>
            )}
         </Draggable>
      )
   }



   return (
      <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
         {(provided, snapshot) => (
            <div
               onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave}
               ref={provided.innerRef}
               {...provided.draggableProps}
               {...provided.dragHandleProps}
               className={`defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
               style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
               id={props.id}
            >

               <TileHeader title={props.item.title} id={props.id} accessLevel={props.accessLevel} edit={true} virtual={true} {...props} />
               <div className="flex-container-row flex-end headerPadding">
               </div>
               {props.large !== true &&
                  <div className="data-tile-shield" />
               }
               {/* <div className="iframeWrapper">
                  <div className="iframe-overlay" style={{
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                     zIndex: 1,
                     opacity: 0,
                     pointerEvents: 'auto',
                  }}
                     onMouseMove={test}
                  ></div>
                  <iframe
                     // onLoad={onIframeLoad}
                     ref={iframeRef}
                     id={props.id}
                     src={"https://" + props.item.src}
                     className="inlineFrameTile"
                     height="99%"
                     width="100%"
                     title={'kratos-tile'}
                     name={'kratos-tile'}
                     allowFullScreen
                     sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                  />
               </div> */}
               {/* <VNCTileIframe 
                  id={props.id}
                  src={props.item.src}
               /> */}
               <VNCTileIframeTest2
                  id={props.id}
                  src={window._env_.REACT_APP_HTTP_PROT + "://" + props.item.src}
                  large={props.large}
               />
            </div>
         )}
      </Draggable>
   );
}
