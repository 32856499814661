/**
 * Lets users enter space
 */
import { Button } from '@rmwc/button'
import { fetcher } from 'net/fetcher'
import { useSelector } from 'react-redux'
import { Link, BrowserRouter, useParams } from 'react-router-dom'

export default function JoinSpaceButton(props) {
   const params = useParams()
   const user = useSelector(state => state.user)

   // I don't want to elevate state to the App level
   // so I am using query parameters to pass inforamtion
   // about which devices to enter with and if they're
   // initially muted or not
   let audioSelection = user.audioDevice.id ? user.audioDevice.id : 'default'
   let videoSelection = user.videoDevice.id ? user.videoDevice.id : 'default'
   let queryParams = new URLSearchParams({
      'audio': !props.audioPreMuted,
      'video': !props.videoPreMuted,
      'audioSource': audioSelection,
      'videoSource': videoSelection
   })

   let url = `/space/${params.roomId}?${queryParams.toString()}`

   let disabled = !props.videoInit

   if (props.audioPreMuted && props.videoPreMuted) disabled = true

   const handleClick = () => {
      window.location.href = url
   }

   function logoutTest() {
      // fetcher('/api/auth/logout').then(result => {
      const origin = window.location.origin;
      fetcher(`/api/auth/keycloakLogout?redirect=${origin}`).then(result => {
         console.warn({ result });
      }).catch(error => {
         console.error(error);
      });
   }

   return (
      <BrowserRouter forceRefresh={true}>
         <div className="join-space-wrapper">
            <Button className="primebtnColor" label="Join Space" onClick={handleClick} raised disabled={false} />
            {props.audioPreMuted && props.videoPreMuted &&
               <p className="deviceError">Please enable Camera or Microphone to Join Space.</p>
            }
            {/* <button onClick={logoutTest}>logout test</button> */}

         </div>
      </BrowserRouter>
   )
}