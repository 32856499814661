import { TextField } from '@rmwc/textfield'
import GuestJoinSpaceButton from 'buttons/GuestJoinSpaceButton';
import { useLayoutEffect, useRef, useState } from 'react';

export default function GuestLogin(props) {

   return (
      <>
         {/* <div className="LoginWindow">
            <div className="LoginFormField">
               <GuestJoinSpaceButton
                  userInput={props.nameInput}
                  {...props}
               />
               {props.audioPreMuted && props.videoPreMuted &&
                  <p className="deviceError guest">Please enable Camera or Microphone to Join Space.</p>
               }
            </div>
         </div> */}
         <div
            style={{
               width: '100%',
            }}
         >
            <GuestJoinSpaceButton
               userInput={props.nameInput}
               {...props}
            />
         </div>
      </>
   );
};