import DefaultLayout from 'layout/DefaultLayout';
import Home from 'pages/Home';
import Preview from 'pages/Preview';
import Space from 'pages/Space';
import GuestPreview from 'pages/GuestPreview';
import RedirectToPreview from 'pages/RedirectToPreview';
import Test from 'pages/Test';
// import "./App.css";
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loading from 'aesthetic/Loading';
import { queue } from 'aesthetic/AlertQueue';
import { SnackbarQueue } from '@rmwc/snackbar';
import '@rmwc/fab/styles';
import '@rmwc/list/styles';
import '@rmwc/button/styles';
import '@rmwc/drawer/styles';
import '@rmwc/icon-button/styles';
import '@rmwc/dialog/styles';
import '@rmwc/menu/styles';
import '@rmwc/tooltip/styles';
import '@rmwc/textfield/styles';
import '@rmwc/icon-button/styles';
import '@rmwc/top-app-bar/styles';
import '@rmwc/tabs/styles';
import '@rmwc/badge/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/ripple/styles';
import '@fontsource/poppins';
import '@fontsource/material-icons';
import 'react-step-progress-bar/styles.css';
import { SocketContext, socketConnection } from 'context/socket';
import { useMyUser } from 'net/user';
// import MattermostChat from "components/temp/MattermostChat";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useContext } from 'react';
import { updateUserList } from 'app/user';
import { updateWidth } from 'app/mattermost';
import { fetcher } from 'net/fetcher';

const SHARE_SCREEN_FIT = 'share-screen-fit';

const VIDEOSIZE = 0.66;
const PrevVIDEOSIZE = 0.6;

function App() {
   const ENV = useSelector((state) => state.env);
   const socket = useContext(SocketContext);
   const dispatch = useDispatch();
   const { data, isLoading } = useMyUser();
   const showMM = useSelector((state) => state.mattermost.show);
   const mmWidth = useSelector((state) => state.mattermost.width);
   const user = useSelector((state) => state.user);
   const calHeight = useSelector((state) => state.calendar.height);
   const showCal = useSelector((state) => state.calendar.show);

   const calRef = useRef(null);

   //!! TEST !!//
   useEffect(() => {
      fetcher('/user-list', 'GET', null, (data) => {
         // dispatch(updateUserList(data));
         console.log({ userlist: data });
      });
   }, []);

   useEffect(() => {
      const title = document.title;
      if (title.includes('|')) return;
      document.title = `${title} | ${ENV.VERSION}`;

      // const bodyEl = document.body;
      // const isRegistered = !!bodyEl.getAttribute('mouse-event-emit-socket');
      // if (isRegistered) return;

      // bodyEl.addEventListener('mousemove', emitShareScreenFit, { capture: true });
   }, []);

   function emitShareScreenFit(evt) {
      if (!socket) return;
      console.log('hi');
      socket.emit(SHARE_SCREEN_FIT);
   }

   // useEffect(() => {

   //    (async () => {
   //       const userList = user.userList;
   //       console.warn({ userList });
   //       const baseURL = window._env_.REACT_APP_NEW_BACKEND_URL;
   //       const url = `${baseURL}/user-list`;
   //       const response = await fetch(url);
   //       if (!response.ok) return;
   //       const json = await response.json();
   //       if (!json.status) return;
   //       const data = json.data;

   //       dispatch(updateUserList(data));

   //       const body = document.body;

   //       // const hasEvent = body.getAttribute('listener');
   //       // if (!hasEvent) return;
   //       const mmIframe = document.getElementById('mm_iframe');
   //       mmIframe?.addEventListener('mousemove', event => {
   //          if (!window.mmResize) return;
   //          const movementX = event.movementX;
   //          console.log(movementX);
   //          // if (!window.mmResize) return;
   //          dispatch(updateWidth(movementX));
   //       }, true);

   //       body.addEventListener('mousemove', event => {
   //          if (!window.mmResize) return;
   //          const movementX = event.movementX;
   //          console.log(movementX);
   //          // if (!window.mmResize) return;
   //          dispatch(updateWidth(movementX));
   //       }, true);

   //       body.addEventListener('mouseup', event => {
   //          window.mmResize = false;
   //       }, true);

   //       body.setAttribute('listener', true);

   //    })();

   // }, []);

   if (isLoading) {
      return <Loading message={`Loading`} />;
   }

   // window.mmResize = false;

   // function onMouseDown(event) {
   //    window.mmResize = true;
   // }

   // function onMouseMove(event) {
   //    if (!window.mmResize) return;
   //    console.log({
   //       event,
   //       mmWidth,
   //    });
   // }

   if (!data) {
      return (
         <SocketContext.Provider value={socketConnection}>
            <Router>
               <DefaultLayout>
                  <Switch>
                     <Route path="/test">
                        <Test />
                     </Route>{' '}
                     <Route path="/r/:roomId">
                        <GuestPreview videoSize={PrevVIDEOSIZE} />{' '}
                     </Route>{' '}
                     <Route path="/space/:roomId">
                        <RedirectToPreview />
                     </Route>{' '}
                     <Route path="/">
                        <Home />
                     </Route>{' '}
                  </Switch>{' '}
               </DefaultLayout>{' '}
            </Router>{' '}
         </SocketContext.Provider>
      );
   } else {
      sessionStorage.setItem('userId', data._id);
      return (
         <SocketContext.Provider value={socketConnection}>
            <Router>
               <DefaultLayout>
                  <SnackbarQueue messages={queue.messages} />{' '}
                  <Switch>
                     <Route path="/test">
                        <Test />
                     </Route>{' '}
                     <Route path="/r/:roomId">
                        <Preview videoSize={PrevVIDEOSIZE} />{' '}
                     </Route>{' '}
                     <Route path="/space/:roomId">
                        <Space videoSize={VIDEOSIZE} />{' '}
                     </Route>{' '}
                     <Route path="/">
                        <Home />
                     </Route>{' '}
                  </Switch>{' '}
               </DefaultLayout>{' '}
            </Router>{' '}
         </SocketContext.Provider>
      );
   }
}

// export function redirectToLog() {
//    const url = getRedirUrl();
//    window.location.href = url;
// }

// function getRedirUrl() {
//    return (
//       window._env_.REACT_APP_API_SERVER +
//       "/api/auth/keycloakAuth?redirectTo=" +
//       window.location.href
//    );
// }

function getUserEmail(data) {
   if (data) {
      return true;
   } else {
      return false;
   }
}

export default App;

// Original 3.8.0 changes

// import DefaultLayout from "layout/DefaultLayout";
// import Home from "pages/Home";
// import Preview from "pages/Preview";
// import Space from "pages/Space";
// import GuestPreview from "pages/GuestPreview";
// import RedirectToPreview from "pages/RedirectToPreview";
// // import "./App.css";
// import "./App.scss";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import React from "react";
// import Loading from "aesthetic/Loading";
// import { queue } from "aesthetic/AlertQueue";
// import { SnackbarQueue } from "@rmwc/snackbar";
// import "@rmwc/fab/styles";
// import "@rmwc/list/styles";
// import "@rmwc/button/styles";
// import "@rmwc/drawer/styles";
// import "@rmwc/icon-button/styles";
// import "@rmwc/dialog/styles";
// import "@rmwc/menu/styles";
// import "@rmwc/tooltip/styles";
// import "@rmwc/textfield/styles";
// import "@rmwc/icon-button/styles";
// import "@rmwc/top-app-bar/styles";
// import "@rmwc/tabs/styles";
// import "@rmwc/badge/styles";
// import "@rmwc/snackbar/styles";
// import "@rmwc/ripple/styles";
// import "@fontsource/poppins";
// import "@fontsource/material-icons";
// import "react-step-progress-bar/styles.css";
// import { SocketContext, socketConnection } from "context/socket";
// import { useMyUser } from "net/user";
// // import MattermostChat from "components/temp/MattermostChat";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useRef } from "react";
// import { updateUserList } from "app/user";
// import { updateWidth } from "app/mattermost";
// import { fetcher } from "net/fetcher";

// const VIDEOSIZE = 0.66;
// const PrevVIDEOSIZE = 0.6;

// function App() {
//    const ENV = useSelector((state) => state.env);
//    const dispatch = useDispatch();
//    const { data, isLoading } = useMyUser();
//    const showMM = useSelector((state) => state.mattermost.show);
//    const mmWidth = useSelector((state) => state.mattermost.width);
//    const user = useSelector((state) => state.user);
//    const calHeight = useSelector((state) => state.calendar.height);
//    const showCal = useSelector((state) => state.calendar.show);

//    const calRef = useRef(null);

//    //!! TEST !!//
//    useEffect(() => {
//       fetcher("/user-list", "GET", null, (data) => {
//          // dispatch(updateUserList(data));
//          console.log({ userlist: data });
//       });
//    }, []);

//    useEffect(() => {
//       const title = document.title;
//       if (title.includes("|")) return;
//       document.title = `${title} | ${ENV.VERSION}`;
//    }, []);

//    // useEffect(() => {

//    //    (async () => {
//    //       const userList = user.userList;
//    //       console.warn({ userList });
//    //       const baseURL = window._env_.REACT_APP_NEW_BACKEND_URL;
//    //       const url = `${baseURL}/user-list`;
//    //       const response = await fetch(url);
//    //       if (!response.ok) return;
//    //       const json = await response.json();
//    //       if (!json.status) return;
//    //       const data = json.data;

//    //       dispatch(updateUserList(data));

//    //       const body = document.body;

//    //       // const hasEvent = body.getAttribute('listener');
//    //       // if (!hasEvent) return;
//    //       const mmIframe = document.getElementById('mm_iframe');
//    //       mmIframe?.addEventListener('mousemove', event => {
//    //          if (!window.mmResize) return;
//    //          const movementX = event.movementX;
//    //          console.log(movementX);
//    //          // if (!window.mmResize) return;
//    //          dispatch(updateWidth(movementX));
//    //       }, true);

//    //       body.addEventListener('mousemove', event => {
//    //          if (!window.mmResize) return;
//    //          const movementX = event.movementX;
//    //          console.log(movementX);
//    //          // if (!window.mmResize) return;
//    //          dispatch(updateWidth(movementX));
//    //       }, true);

//    //       body.addEventListener('mouseup', event => {
//    //          window.mmResize = false;
//    //       }, true);

//    //       body.setAttribute('listener', true);

//    //    })();

//    // }, []);

//    if (isLoading) {
//       return <Loading message={`Loading`} />;
//    }

//    // window.mmResize = false;

//    function onMouseDown(event) {
//       window.mmResize = true;
//    }

//    function onMouseMove(event) {
//       if (!window.mmResize) return;
//       console.log({
//          event,
//          mmWidth,
//       });
//    }

//    const isLoggedInUser = !!(data && data._id);
//    if (isLoggedInUser) sessionStorage.setItem("userId", data._id);

//    return (
//       <SocketContext.Provider value={socketConnection}>
//          <Router>
//             <DefaultLayout onMouseMove={onMouseMove}>
//                <Switch>
//                   <Route path="/r/:roomId">
//                      {isLoggedInUser ? (
//                         <Preview videoSize={PrevVIDEOSIZE} />
//                      ) : (
//                         <GuestPreview videoSize={PrevVIDEOSIZE} />
//                      )}
//                   </Route>{" "}
//                   <Route path="/">
//                      <Home />
//                   </Route>{" "}
//                   <Route path="/space/:roomId">
//                      {isLoggedInUser ? (
//                         <Space videoSize={VIDEOSIZE} />
//                      ) : (
//                         <RedirectToPreview />
//                      )}
//                   </Route>{" "}
//                </Switch>{" "}
//             </DefaultLayout>{" "}
//          </Router>{" "}
//       </SocketContext.Provider>
//    );
// }

// export function redirectToLog() {
//    const url = getRedirUrl();
//    window.location.href = url;
// }

// function getRedirUrl() {
//    return (
//       window._env_.REACT_APP_API_SERVER +
//       "/api/auth/keycloakAuth?redirectTo=" +
//       window.location.href
//    );
// }

// export default App;
