import { Button } from '@rmwc/button';
import { Tooltip } from '@rmwc/tooltip';
import { muteVideoPreview } from 'js/webex-controls';
import { useDispatch, useSelector } from 'react-redux';
import { assignAudioDevice, assignVideoDevice, updateVideoPreMuted } from 'app/user';
import { useState } from 'react';
import { useEffect } from 'react';

export default function PreviewMuteVideoButton(props) {
   const dispatch = useDispatch();
   // const $user = useSelector(state => state.user);
   const videoPreMuted = useSelector(state => state.user.videoPreMuted);
   const videoDevice = useSelector(state => state.user.videoDevice);

   // const handleClick = () => {
   //    props.setVideoPreMuted(!props.videoPreMuted);
   //    muteVideoPreview(!props.videoPreMuted);
   // }

   async function handleClick() {
      const newValue = !videoPreMuted;
      dispatch(updateVideoPreMuted(newValue));
      await muteVideoPreview(newValue);
      const { key } = videoDevice;
      setTimeout(() => {
         dispatch(assignVideoDevice({ key: key + 1 }));
      }, 100);
   }
   const isError = !!videoDevice.error;

   return (
      <Tooltip content={props.videoPreMuted ? 'Turn Camera On' : 'Turn Camera Off'}>
         <Button
            onClick={handleClick}
            className="btnLeft"
            style={{
               color: videoPreMuted ? 'red' : ''
            }}
            icon={videoPreMuted ? 'videocam_off' : 'videocam'}
            disabled={isError}
         >
         </Button>
      </Tooltip>
   );
}