import Loading from "aesthetic/Loading"
import TilePanelSmall from "components/core/TilePanelSmall";
import TilePanelLarge from "components/core/TilePanelLarge";
import CallControlsFixed from "components/CallControlsFixed";
import WebexVideo from "components/WebexVideo";
import JitsiVideo, { JitsiVideoMemo } from "components/JitsiVideo";
import SidebarToggle from "buttons/SidebarToggle";
import Cursor from "aesthetic/Cursor";
import Swal from 'sweetalert2'
import Header from "aesthetic/Header";
import { addElementMedia, admitLobbyUser, joinMeeting, removeLobbyUser, setupWebex, getCurrentMeeting, leaveCall } from "js/webex-controls"
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom"
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid'
import { updateTiles } from "net/tiles";
import { useRoom } from "net/rooms";
import { depositAssetLocker, unlinkAsset, uploadAsset, useAssets } from "net/assets";
import { permitted, useMyUser } from "net/user";
import { useSlackUsers } from 'net/slack'
import { queue } from 'aesthetic/AlertQueue'
import { SocketContext } from "context/socket";
import { useDispatch, useSelector } from 'react-redux';
import { assignLevel, assignPdfPage } from "app/view";
import { assignTeamId } from "app/space";
import { assignAudioDevice, assignDevices, assignEmail, assignGuest, assignId, assignName, assignOwner, assignVideoDevice, updateAudioPreMuted, updateIsOwner, updateVideoPreMuted } from "app/user";
import messages, { addNewMessage } from "app/messages";
import { assignSlackSelf, assignSlackUsers } from "app/slack";
import { assignParticipants } from "app/participants";
// import MattermostChat from "components/temp/MattermostChat";
import AnnotationCanvas from "components/AnnotationCanvas";
import MattermostChat from "components/temp/MattermostChat";
import { generateToken, getConnections } from "net/guac";
import {
   available as updateRDPAvailable,
   connections as updateRDPConnections,
   token as updateToken,
} from "app/rdp";
import Logo from "aesthetic/Logo";
import NdrcPIP from "components/temp/NdrcPIP";
import { updateJitsiKey, updateShow as updateVideoShow, updateShowBeforeFullscreen as updateVideoShowBeforeFullscreen } from "app/video";
import { assignFullScreen } from 'app/view';
import { updateRoom } from "app/room";


const VALID_TILES = ['screen-share', 'whiteboard', 'vnc', 'utilities-tile', 'calendar', 'iframe']
const FOLDER_TILES = ['screen-share', 'whiteboard', 'utilities-tile', 'calendar']

// Adding Guest Auth functionality
const guestLogin = 'guest-login'
const guestCallIn = 'guest-call-in'
const tokenPass = 'guest-token'
const sceneUpdate = 'scene-string'
const screenShare = 'screen-share'
const pdfPageChange = 'pdf-page-change'
const whiteboardClick = 'whiteboard-click'
const calendarClick = "calendar-click"
const closeTile = 'close-tile'
const showSmallList = 'show-small-list'
const slackMessage = 'slack-message'
const keepAlive = 'keep-alive'
const socketGuestTokenRef = useRef


export default function Space(props) {
   const params = useParams();
   const socket = useContext(SocketContext)
   const dispatch = useDispatch()
   const viewSelector = useSelector(state => state.view)
   const viewRef = useRef(viewSelector.level)
   const user = useSelector(state => state.user)
   const userRef = useRef(user)
   const slackMessenger = useSelector(state => state.slack.slackMessenger)
   const slackMessengerRef = useRef(slackMessenger)
   const slackUsers = useSlackUsers()
   const allSlackUsers = useSelector(state => state.slack.allUsers)
   const allSlackUsersRef = useRef(allSlackUsers)
   const slackSelf = useSelector(state => state.slack.slackSelf)
   const slackSelfRef = useRef(slackSelf)

   const [loading, setLoading] = useState(false);
   const [endCallBlock, setEndCallBlock] = useState(false)
   const [leaving, setLeaving] = useState(false);
   const [teamId, setTeamId] = useState(undefined)
   const teamIdRef = useRef(teamId)

   const [tileListSmall, _setTileListSmall] = useState([]);
   const [tileListLarge, _setTileListLarge] = useState([]);
   const [smallListShow, setSmallListShow] = useState(true);
   const [annotationOpen, setAnnotationOpen] = useState(false);

   const mmShow = useSelector(state => state.mattermost.show)
   const mmWidth = useSelector(state => state.mattermost.width)

   const videoState = useSelector(state => state.video);
   const shareScreenMode = videoState.shareScreenMode;
   const videoShowBeforeFullscreen = videoState.showBeforeFullscreen;
   const mainTileFullscreen = viewSelector.fullScreen;

   const jitsiKey = videoState.jitsiKey;

   function onMouseDown(event) {
      window.mmResize = true;
   }

   function onMouseMove(event) {
      if (!window.mmResize) return;
      console.log({ event, mmWidth });
   }



   const tileListSmallRef = useRef(tileListSmall)
   // set ref update and state update so they stay in sync
   const setTileListSmall = (newList) => {
      tileListSmallRef.current = newList;
      _setTileListSmall(newList);
   }

   const tileListLargeRef = useRef(tileListLarge)
   // set ref update and state update so they stay in sync
   const setTileListLarge = (newList) => {
      tileListLargeRef.current = newList
      _setTileListLarge(newList)
   }

   const [accessLevel, setAccessLevel] = useState(undefined)

   const query = new URLSearchParams(window.location.search)

   const audioPreMuted = query.get('audio') !== 'true'
   const videoPreMuted = query.get('video') !== 'true'
   // const [selfVideoOn, setSelfVideoOn] = useState(videoPreMuted ? false : true);
   useEffect(() => {
      dispatch(updateVideoPreMuted(videoPreMuted));
      dispatch(updateAudioPreMuted(audioPreMuted));
   }, []);

   const selfVideoOn = useSelector(state => !state.user.videoPreMuted)
   const setSelfVideoOn = (unmute) => {
      const mute = !unmute;
      dispatch(updateVideoPreMuted(mute));
   }

   const audioSource = query.get('audioSource')
   const videoSource = query.get('videoSource')
   const room = useRoom(params.roomId)
   if (room.data && !room.isError) {
      dispatch(updateRoom(room.data))
   }
   const roomRef = useRef(room)
   const authedUser = useMyUser()

   const assets = useAssets(params.roomId)





   function guacAutoLogin() {
      if (room.data) {
         const isFirstTrigger = !window.guac_intervs;
         (() => {
            if (!window) return;
            const logStyle = `background:#023020;color:white;border:1px solid white;font-size:18px;padding:10px;`;
            const roomData = room.data;
            const scene2d = roomData?.scene2d;
            const dataTileDisplaying = scene2d?.['dataTile00'];
            const dataTileList = scene2d?.['dataTile01'];
            if (!dataTileList || dataTileList.length < 1) return;

            const allTiles = [...dataTileDisplaying, ...dataTileList];

            const foundGuacs = allTiles.filter(tile => {
               const isGuac = tile.type?.toLowerCase?.().includes('guac');
               // if (isGuac) foundGuacs.push(tile);
               return isGuac
            });

            // console.warn('foundGuacs', foundGuacs);

            if (!foundGuacs || foundGuacs.length < 1) return;

            const clearGuacInterval = (guacId) => {
               const guacIntervals = window.guac_intervs;
               if (!guacIntervals) return;
               for (let i = 0; i < guacIntervals.length; i++) {
                  const guacInte = guacIntervals[i];
                  console.warn('guacInte', guacInte);
                  if (guacInte.guacId === guacId) {
                     console.warn('Clearing guac interval', guacId);
                     window.clearInterval(guacInte.intervalId);
                     guacIntervals.splice(i, 1);
                     break;
                  }
               }
            }

            const repeatDetect = (guac) => {
               const guacId = guac._id;
               const guacIframeEl = document?.querySelector(`#${guacId}.inlineFrameTile`);
               if (!guacIframeEl) return;
               console.warn('guacIframeEl', guacIframeEl);

               //>> Will only work under same domain.. <<//
               const guacDocument = guacIframeEl.contentDocument;
               console.warn('guacDocument', guacDocument);
               //!! If not the same domain, stop interval !!//
               if (!guacDocument) {
                  const originURL = window.location.origin;
                  const iframeURL = guacIframeEl.getAttribute('src');
                  (isFirstTrigger) && (window.alert(`GUAC AND HOST DN ARE NOT SAME!\n\nCurrent: ${originURL}\nIframe: ${iframeURL}\n\nPlease make sure of same DN for Guac customization to work!`));
                  console.log('');
                  console.log(`%cGUAC IS IN DIFFERENT DOMAIN!\nCURRENT DOMAIN: ${originURL}\nIFRAME DOMAIN: ${iframeURL}\nTERMINATING GUAC INTERVAL FOR GUAC ID: ${guacId}`, logStyle);
                  console.log('');
                  clearGuacInterval(guacId);
                  return;
               }

               //@@ If same domain, trigger event in guac iframe @@//
               const guacLoginForm = guacDocument.querySelector('form.login-form');
               console.warn('guacLoginForm', guacLoginForm);
               //@@ If already logged in (weak detection), celear interval @@//
               if (!guacLoginForm) {
                  console.log('');
                  // console.log(`%cGUAC ALREADY LOGGED IN!\nCHECK IFRAME TO VALIDATE\nTERMINATING INTERVAL FOR GUAC ID: ${guacId}`, logStyle);
                  console.log(`%cCOULD NOT DETECT FORM ELEMENT IN GUAC IFRAME!`, logStyle);
                  console.log('');
                  clearGuacInterval(guacId);
                  return;
               }

               //@@ If not logged in, attempt auto login @@//
               const usernameInput = guacLoginForm.querySelector('input[type="text"]');
               const passwordInput = guacLoginForm.querySelector('input[type="password"]');
               const loginInput = guacLoginForm.querySelector('input[type="submit"]');

               if (!usernameInput || !passwordInput || !loginInput) {
                  console.log('');
                  console.log('%cCOULD NOT DETECT GUAC IFRAME FORM CHILDREN ELEMENTS!', logStyle);
                  console.log('');
                  clearGuacInterval(guacId);
                  return;
               }

               usernameInput.value = window._env_.REACT_APP_GUACAMOLE_DEFAULT_USERNAME || 'guacadmin';
               passwordInput.value = window._env_.REACT_APP_GUACAMOLE_DEFAULT_PASSWORD || 'guacadmin';

               const submitEvt = new Event('submit');
               guacLoginForm.dispatchEvent(submitEvt);

               clearGuacInterval(guacId);

            }

            if (!window.guac_intervs) window.guac_intervs = [];

            foundGuacs.forEach((guac, ind) => {
               const guacIntervs = window.guac_intervs;

               let exists = false;
               for (let i = 0; i < guacIntervs.length; i++) {
                  const inter = guacIntervs[i];
                  if (!inter) break;
                  if (inter.guacId === guac._id) {
                     exists = true;
                     break;
                  }
               }

               if (exists) return;

               const intervId = setTimeout(() => repeatDetect(guac, ind), 1000);

               const guacInterv = { intervalId: intervId, index: ind, guacId: guac._id };
               window.guac_intervs.push(guacInterv);

            });
         })();
      }
   }

   guacAutoLogin();

   // const [jitsiKey, setJitsiKey] = useState(1);

   function addResizeObserver(element) {
      console.warn('addResizeObserver()', element);
      const isElement = element instanceof HTMLElement;
      if (!isElement) return;

      const timeout = 100;
      let intervalId;
      const setTimeoutDebounce = (callback) => {
         if (!callback || typeof callback !== 'function') return;
         if (intervalId) {
            clearInterval(intervalId);
         }
         intervalId = setTimeout(() => {
            callback();
            intervalId = null;
         }, timeout);
      }

      const resizeObserver = new ResizeObserver(entries => {
         // for (const entry of entries) {
         //    console.log(entry);
         // }
         const callback = () => {
            window.dispatchEvent(new Event('resize'));
         }
         setTimeoutDebounce(callback);
      });

      resizeObserver.observe(element);
   }

   useEffect(() => {
      const mainTile = document.querySelector('.largeTileClass.observe-resize');
      if (!mainTile) return;
      addResizeObserver(mainTile);
   }, []);

   useEffect(() => {
      (async () => {
         const result = await generateToken();
         const status = result?.status || false;
         const data = result?.data;
         (data) && (window.localStorage.setItem('GUAC_AUTH', JSON.stringify(data)));
         dispatch(updateRDPAvailable(status));
         if (!status || !data) return;
         dispatch(updateToken(data));
         const connResult = await getConnections();
         if (!connResult || !connResult.status || !connResult.data) return;
         const connData = connResult.data;
         dispatch(updateRDPConnections(connData));
      })();

   }, []);






   const [guestLoginQueue, _setGuestLoginQueue] = useState([]);
   const guestLoginRef = useRef(guestLoginQueue)
   const setGuestLoginQueue = (newQueue) => {
      _setGuestLoginQueue(newQueue)
      guestLoginRef.current = newQueue
   }
   const [inProcess, setProcess] = useState(false);
   // we also need to keep track of who is sharing (remote, local, none)
   const [sharing, setSharing] = useState()

   // We need to set a timeout so we are able to detect whether the user canceled the browser close or now
   var timeout;

   function warning() {
      timeout = setTimeout(function () {
      }, 1000);
      if (!endCallBlock) {
         // This opens up the browser console confirmation modal
         return 'You are leaving the space'
      }
   }

   function noTimeout(e) {
      if (!endCallBlock) {
         // process for leaving the call
         // we don't need to perform this if the end call button is pressed
         if (!leaving) {
            setLeaving(true)
            setLoading(true);
            leaveCall(params.roomId, props.accessLevel).then(() => {
               socket.disconnect()
               window.location = window._env_.REACT_APP_PLATFORM
            }).catch((e) => {
               socket.disconnect()
               console.error(e)
               window.location = window._env_.REACT_APP_PLATFORM
            })
            delete e['returnValue']
         }
      } else if (endCallBlock) {
         // do nothing
      }
   }

   window.onbeforeunload = warning;
   window.onunload = noTimeout;

   const onDragEnd = useCallback((result) => {
      const { source, destination, combine, draggableId } = result;
      // disables dragging the entire utility tile to the large tile

      if (combine !== null) {
         if (source.droppableId.includes('large-tile-list')) {
            queue.notify({
               title: 'Can not add main tile to folder.'
            })
            return;
         }

         if (tileListSmallRef.current[source.index].fileName === 'folder') {
            queue.notify({
               title: 'Can not use folder to create folder.'
            })
            return;
         }
         // createFolder(source, combine, tileListSmall, tileListLarge, params.roomId)
      }

      if (!source || !destination) return;
      if (viewSelector.level !== 'all') {
         insideFolderMove(source, destination, tileListSmall, tileListLarge, params.roomId, viewSelector.level)
         return
      }
      dataTileMove(source, destination, tileListSmall, tileListLarge, params.roomId, viewSelector.level, draggableId)
   })

   useEffect(() => {
      roomRef.current = room
      teamIdRef.current = teamId
      viewRef.current = viewSelector.level
      userRef.current = user
      slackMessengerRef.current = slackMessenger
      allSlackUsersRef.current = allSlackUsers
      slackSelfRef.current = slackSelf
   })

   useEffect(() => {
      socketGuestTokenRef.current = socket
   }, [])

   useEffect(() => {
      if (loading) {
         setupWebex(params.roomId).then(() => {
            setLoading(false)
            return joinMeeting(audioSource, videoSource, audioPreMuted, videoPreMuted)
         }).then((localStream) => {
            // after everything is said and done, we want to double check all the
            // selections are correct
            let audio = localStream.getAudioTracks()[0]
            let video = localStream.getVideoTracks()[0]
            navigator.mediaDevices.enumerateDevices().then((devices) => {
               devices.forEach((device) => {
                  if (audio !== undefined && device.label === audio.label) {
                     dispatch(assignAudioDevice({ label: device.label, id: device.deviceId }))
                  }
                  if (video !== undefined && device.label === video.label) {
                     dispatch(assignVideoDevice({ label: device.label, id: device.deviceId }))
                  }
               })
               dispatch(assignDevices(devices))
            })
            // we need some way of knowing whether a share is going on
            // when we join the meeting to properly disable the share button
            // so we check to see if the screen share video element is present in the scene
            if (document.getElementById('screen-share-video') !== null) setSharing('remote')
            addElementMedia(sharing)
         })
      }
   }, [])

   // Hook to set up information about user in Redux Store
   useEffect(() => {
      if (authedUser.data) {
         if (authedUser.data.role !== undefined) dispatch(assignGuest(false))
         if (authedUser.data.role === "super-admin") dispatch(assignOwner(true))
         if (authedUser.data.role === "super-admin") dispatch(updateIsOwner(true))
         dispatch(assignId(authedUser.data._id))
         dispatch(assignEmail(authedUser.data.email))
         dispatch(assignName(authedUser.data.name))

         // we want to fetch slack token as soon as we have user ID
         fetch(`${window._env_.REACT_APP_API_SERVER}/api/slack/${authedUser.data._id}/${params.roomId}/fetchToken`, {
            method: 'GET',
            credentials: 'include'
         })
      }
   }, [authedUser.data])

   useEffect(() => {
      let keepAliveInt;
      if (socket.connected && authedUser.data.name !== undefined) {
         keepAliveInt = setInterval(() => {
            socket.emit(keepAlive, { name: authedUser.data.name, id: authedUser.data._id, time: new Date().getTime() })
         }, 5000);
      }

      return () => {
         clearInterval(keepAliveInt);
      }
   }, [socket.connected, authedUser.data])

   useEffect(() => {
      if (slackUsers.data !== undefined) {
         slackUsers.data.forEach((slackUser) => {
            if (slackUser.email === user.email) dispatch(assignSlackSelf(slackUser))
            dispatch(assignSlackUsers(slackUser))
         })
      }
   }, [slackUsers.data])

   // I modified the updating Tile list logic to only update state if the scene string actually changes
   // Anytime state on the tile lists are updated causes rerenders
   useEffect(() => {
      if (room.data && assets.data) {
         dispatch(assignTeamId(room.data.teamId))
         setTeamId(room.data.teamId)

         if (JSON.stringify(tileListLarge) !== JSON.stringify(room.data.scene2d.dataTile00) && JSON.stringify(tileListSmall) !== JSON.stringify(room.data.scene2d.dataTile01) || room.data.scene2d.dataTile01.length === 0) {
            setTileListLarge(room.data.scene2d.dataTile00)
            manageSmallTileList(room.data.scene2d.dataTile00, room.data.scene2d.dataTile01, assets.data.data)
         } else if (JSON.stringify(tileListLarge) === JSON.stringify(room.data.scene2d.dataTile00) && JSON.stringify(tileListSmall) !== JSON.stringify(room.data.scene2d.dataTile01)) {
            manageSmallTileList(tileListLarge, room.data.scene2d.dataTile01, assets.data.data)
         } else if (JSON.stringify(tileListLarge) !== JSON.stringify(room.data.scene2d.dataTile00) && JSON.stringify(tileListSmall) === JSON.stringify(room.data.scene2d.dataTile01)) {
            setTileListLarge(room.data.scene2d.dataTile00)
         }
         initPdfPageTracking(assets.data.data)
      }
   }, [room.data, assets.data])

   const videoShow = useSelector(state => state.video.show)

   // useEffect(() => {
   //    console.warn({ mainTileFullscreen, videoShow });
   //    if (mainTileFullscreen) {
   //       dispatch(updateVideoShowBeforeFullscreen(videoShow))
   //    }
   // }, [mainTileFullscreen, videoShow])

   useEffect(() => {
      // window._cache_ = {
      //    ...(window._cache_ || {}),
      //    videoShowBeforeFullscreen: videoShow
      // }

      socket.on("large-tile-fullscreen", (payload) => {

         // console.log('current video show', videoShow);
         // console.log('current video show', window._cache?.videoShowBeforeFullscreen);
         console.log('larget-tile-full-screen');
         dispatch(assignFullScreen(payload));
         const el = document.getElementsByClassName('largeTileClass')[0];
         el.classList[payload ? 'add' : 'remove']('fullScreen');
         //>> If fullscreened <<//
         if (payload) {
            // dispatch(updateVideoShowBeforeFullscreen(videoShow))
            console.log('%cMAXIMIZE MAIN ASSET', 'background:black;color:white;border:1px solid white;padding:5px;');

            // window._cache_ = {
            //    ...(window._cache_ || {}),
            //    videoShowBeforeFullscreen: videoShow
            // }
            // dispatch(updateVideoShow(false));
         }
         //>> If not fullscreened <<//
         if (!payload) {
            console.log('%cMINIMIZE MAIN ASSET', 'background:black;color:white;border:1px solid white;padding:5px;');
            // const cachedVideoShow = window._cache_?.videoShowBeforeFullscreen;
            // console.log('cachedVideoShow', cachedVideoShow);
            // dispatch(updateVideoShow(!!cachedVideoShow));
            dispatch(updateVideoShow(videoShowBeforeFullscreen));
         }
      });

      socket.on(keepAlive, (message) => {
         if (user._id !== message.message.id) {
            dispatch(assignParticipants(message.message))
         }
      })

      socket.on(showSmallList, (message) => {
         setSmallListShow(message.message)
      })

      socket.on(closeTile, (message) => {
         handleTileClose(message.message._id, tileListSmallRef, tileListLargeRef, teamIdRef.current, params.roomId, viewRef.current, dispatch)
      })

      socket.on(whiteboardClick, (message) => {
         console.log({ message });
         setupWhiteboard(message.message.inUse, tileListSmallRef, tileListLargeRef, params.roomId)
      })
      socket.on(calendarClick, (message) => {
         setupCalendar(message.message.inUse, tileListSmallRef, tileListLargeRef, params.roomId)
      })

      socket.on(screenShare, (message) => {
         let shareState = undefined
         // only the person who pressed the button should make the call to update the scene string/tile layout
         if (userRef.current.webex.id === message.message.id) {
            if (message.message.sharing) shareState = 'local'
            setupScreenShare(message.message.sharing, tileListSmallRef, tileListLargeRef, params.roomId)
         } else if (message.message.sharing) {
            // otherwise we want to indicate a remote share
            shareState = 'remote'
         } else {
            document.getElementById('share-button-id').style.backgroundColor = 'unset'
            // or reset the value
            shareState = undefined
         }
         setSharing(shareState)
         addElementMedia(shareState)
      })

      socket.on(slackMessage, (message) => {
         let name, pfp

         allSlackUsersRef.current.forEach((slackUser) => {
            if (message.message.sendFrom === slackUser.id) {
               name = slackUser.name
               pfp = slackUser.pfp
            }
         })
         // if the message is sent to us, but not from us
         if (message.message.sendFrom !== slackSelfRef.current.id) {
            let time = new Date()
            let hours = time.getHours()
            let suffix = 'AM'
            if (hours > 12) {
               hours -= 12
               suffix = 'PM'
            }
            let min = time.getMinutes()
            if (min < 10) min = `0${min}`
            dispatch(addNewMessage({
               [message.message.sendTo]: {
                  id: message.message.sendFrom,
                  text: message.message.message,
                  timestamp: `${hours}:${min} ${suffix}`,
                  pfp: pfp,
                  name: name
               }
            }))
         }
      })
   }, [])

   // useEffect(() => {
   //    // make sure to stop sharing if a user closes the tab
   //    // to avoid stale tile bug
   //    window.addEventListener('beforeunload', () => {
   //       const meeting = getCurrentMeeting()
   //       if (!meeting) return;
   //       if (meeting.isSharing) {
   //          meeting.stopShare()
   //          setupScreenShare(false, tileListSmallRef, tileListLargeRef, params.roomId)
   //          return
   //       }
   //       return
   //    }, false)
   // }, [])

   useEffect(() => {
      permitted(params.roomId).then((accessData) => {
         let accessLevel
         try {
            if (authedUser.data.role === "super-admin") {
               accessLevel = 'owner'
               setAccessLevel(accessLevel)
            } else {
               accessLevel = accessData.accessLevel[0].accessLevel
               setAccessLevel(accessLevel)
            }

         } catch (e) {
            accessLevel = null
            setAccessLevel(accessLevel)
         } finally {
            // dispatch(assignOwner(accessLevel === 'owner' ? true : false));
            const isOwner = accessLevel === 'owner';
            dispatch(updateIsOwner(isOwner));
         }
         if (accessLevel === null) {
            //
         } else if (accessLevel === 'owner') {
            socket.on(guestLogin, (comms) => {
               // need to create a new object in memory
               // for .push() to work appropriately.
               console.log('ss');
               let newComms = Object.assign({}, comms)
               let duplicate = false;
               for (let i in guestLoginRef.current) {
                  newComms.socketId === guestLoginRef.current[i].socketId ? duplicate = true : duplicate = duplicate
               }
               if (!duplicate) guestLoginRef.current.push(newComms)
               guestLoginFunc();

            })
            socket.on(guestCallIn, (message) => {
               guestCallInFunc(message);

            })
         }
      }).catch((e) => {
         setAccessLevel(null)
      })
   }, [])

   useEffect(() => {
      socket.on(pdfPageChange, (message) => {
         dispatch(assignPdfPage({ id: message.message.pdfId, page: message.message.newPage }))
      })
   }, [])

   function guestCallInFunc(message) {
      Swal.queue([{
         title: 'Call In User Request to Enter Space',
         showDenyButton: true,
         confirmButtonText: 'Allow',
         denyButtonText: 'Deny',
         text: `Allow ${message.message.name} to join your Space?`,
         showLoaderOnConfirm: true,
         allowOutsideClick: false,
         preDeny: () => {
            removeLobbyUser(message.message.id)
         },
         preConfirm: () => {
            admitLobbyUser(message.message.id)
         }
      }])
   }

   const guestLoginFunc = useCallback(() => {
      if (guestLoginRef.current.length > 0 && inProcess === false) {
         setProcess(true)
         let message = guestLoginRef.current[0]
         let guestData = {
            "name": message.message.name,
            "roomId": params.roomId,
            "id": uuidv4()
         };
         const guestAuthUrl = window._env_.REACT_APP_API_SERVER + "/api/auth/generateGuestToken";
         Swal.queue([{
            title: 'Guest Request to Enter Space',
            showDenyButton: true,
            confirmButtonText: 'Allow',
            denyButtonText: 'Deny',
            text: 'Allow ' + message.message.name + ' to join your Space?',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            willClose: () => {
               guestLoginRef.current.shift()
               setGuestLoginQueue(guestLoginRef.current)
               if (guestLoginRef.current.length > 0) {
                  setProcess(false)
                  guestLoginFunc(guestLoginRef.current);
               } else {
                  setProcess(false)
               }
            },
            preDeny: () => {
               socket.emit(tokenPass, { admit: false, socketId: message.socketId })
            },
            preConfirm: async () => {
               try {
                  const response = await fetch(guestAuthUrl, {
                     method: 'POST',
                     body: JSON.stringify(guestData),
                     credentials: 'include',
                     headers: {
                        'Accept': 'application/json',
                        'Content-Type': "application/json",
                     }
                  });
                  const data = await response.json();
                  socket.emit(tokenPass, { guestToken: data.guestUserSession[0].guestToken, socketId: message.socketId });
               } catch (e) {
                  Swal.insertQueueStep({
                     icon: 'error',
                     title: 'Unable to request your id for ' + message.message.name
                  });
               }
            }
         }])
         const sound = new Audio('/assets/sounds/guest-join.mp3')
         sound.play();
      }
   }, [])

   const initPdfPageTracking = (assetList) => {
      for (let i = 0; i < assetList.length; i++) {
         let ext = assetList[i]?.fileName.split('.').pop()
         if (ext === 'pdf') {
            dispatch(assignPdfPage({ id: assetList[i]._id, page: assetList[i].currPage }))
         }
      }
   }

   const manageSmallTileList = (tileListLarge, tileListSmall, assetList) => {
      let matchLarge = Array.from(tileListLarge)
      let matchSmall = Array.from(tileListSmall)
      let matchAsset = Array.from(assetList)

      let assetInd = matchAsset.length

      while (assetInd--) {
         let matched = false;
         let largeInd = matchLarge.length
         while (largeInd-- && !matched) {
            // also remove webex and screen share from any consideration
            if (VALID_TILES.includes(matchLarge[largeInd].fileName)) {
               matchLarge.splice(largeInd, 1)
            }
            // also remove empty tiles from consideration
            else if (matchLarge[largeInd].fileName === "") {
               matchLarge.splice(largeInd, 1)
            }
            else if (matchAsset[assetInd]._id === matchLarge[largeInd]._id) {
               matched = true
               matchLarge.splice(largeInd, 1)
               matchAsset.splice(assetInd, 1)
            }
         }
         let smallInd = matchSmall.length
         while (smallInd-- && !matched) {
            // check folder contents
            if (matchSmall[smallInd].fileName === 'folder') {
               let matchFolder = Array.from(matchSmall[smallInd].files)
               let folderInd = matchFolder.length
               while (folderInd-- && !matched) {
                  if (matchAsset[assetInd]._id === matchFolder[folderInd]._id) {
                     matched = true
                     matchFolder.splice(folderInd, 1)
                     matchAsset.splice(assetInd, 1)
                  }
               }
               matchSmall.splice(smallInd, 1)
               for (let i = 0; i < matchFolder.length; i++) {
                  matchSmall.push(matchFolder[i])
               }
            }
            // also remove webex and screen share from any consideration
            else if (VALID_TILES.includes(matchSmall[smallInd].fileName)) {
               matchSmall.splice(smallInd, 1)
            }
            // also remove empty tiles from consideration
            else if (matchSmall[smallInd].fileName === "") {
               matchSmall.splice(smallInd, 1)
            }
            else if (matchAsset[assetInd]._id === matchSmall[smallInd]._id) {
               matched = true
               matchSmall.splice(smallInd, 1)
               matchAsset.splice(assetInd, 1)
            }
         }
      }

      let newLarge = Array.from(tileListLarge)
      let newSmall = Array.from(tileListSmall)
      assetInd = assetList.length
      // if we have leftover tiles in the small list that haven't been matched to an asset list item
      if (matchSmall.length !== 0) {
         // we want to remove them from the small tile list
         console.warn({ matchSmall });
         matchSmall.forEach((matchVal) => {
            let smallInd = newSmall.length
            // modifying in place so iterate backwards
            while (smallInd--) {
               if (matchVal.fileName === 'folder') {
                  let matchFolder = Array.from(matchVal.files)
                  let folderInd = matchFolder.length
                  while (folderInd--) {
                     if (matchFolder[folderInd]._id === newSmall[smallInd]._id) newSmall.splice(smallInd, 1)
                     else if (newSmall[smallInd].fileName === 'folder') {
                        let smallFolderInd = newSmall[smallInd].files.length
                        while (smallFolderInd--) {
                           if (matchFolder[folderInd]._id === newSmall[smallInd].files[smallFolderInd]._id) {
                              newSmall[smallInd].files.splice(smallFolderInd, 1)
                           }
                        }
                     }
                  }
               }
               else if (!VALID_TILES.includes(matchVal.fileName)) {
                  if (newSmall[smallInd].fileName === 'folder') {
                     let smallFolderInd = newSmall[smallInd].files.length
                     while (smallFolderInd--) {
                        if ((matchVal._id === newSmall[smallInd].files[smallFolderInd]._id)) newSmall[smallInd].files.splice(smallFolderInd, 1)
                     }
                  }
                  else if (matchVal._id === newSmall[smallInd]._id) newSmall.splice(smallInd, 1)
               }
            }
         })
      }
      // if we have leftover tiles in the large list that haven't been matched to an asset list item
      else if (matchLarge.length !== 0) {
         // we know the large tile list can only hold one tile so it's easy peasy
         // just take the last element from the small list and make it the large list
         newLarge = [newSmall.pop()]
      }
      // if we have extra unmatched asset tiles 
      else if (matchAsset.length !== 0) {
         // check to see if we have empty tiles
         newSmall.forEach((val, index) => {
            if (matchAsset.length > 0 && val.fileName === "") {
               newSmall.splice(index, 1, matchAsset.pop())
            }
         })
         newSmall = newSmall.concat(matchAsset)
      }

      setTileListSmall(newSmall)
      setTileListLarge(newLarge)
      updateTiles(params.roomId, newSmall, newLarge);
   }

   const checkForFiles = (e) => {
      let hasFiles = false;
      if (e.dataTransfer) {
         const types = e.dataTransfer.types
         for (let i in types) {
            if (types[i] === 'Files') {
               hasFiles = true;
               return hasFiles
            }
         }
      }
      return hasFiles
   }

   const fileDropDragOver = (e) => {
      e.preventDefault()
      let panel = document.querySelector('.panelBG')
      let el = document.querySelector('.file-drop-image')
      el.classList.add('drag-over')
      panel.style.pointerEvents = 'none'
   }

   const fileDropDragLeave = (e) => {
      let wrapper = document.querySelector('.SmallPanelContainer')
      let panel = document.querySelector('.panelBG')
      let el = document.querySelector('.file-drop-image')
      if (e.clientY >= (wrapper.offsetTop + wrapper.offsetHeight) || e.clientY <= wrapper.offsetTop || e.clientX >= (wrapper.offsetLeft + wrapper.offsetWidth) || e.clientX >= wrapper.offsetWidth) {
         el.classList.remove('drag-over')
         panel.style.pointerEvents = 'unset'
      }
   }

   const uploadFiles = (e) => {
      e.preventDefault()
      fileDropDragLeave({ clientY: 0, clientX: 0 })
      let hasFiles = checkForFiles(e)
      if (!hasFiles) return

      let files = e.dataTransfer.files
      let newFiles = []
      if (accessLevel === 'owner') {
         for (let i = 0; i < files.length; i++) {
            uploadAsset(teamId, files[i]).then((res) => {
               if (res.success) {
                  depositAssetLocker(teamId, params.roomId, files[i].name).then((res) => {
                     newFiles.push(res.data[0])
                     if (newFiles.length === files.length) {
                        let newSmall = Array.from(tileListSmall)
                        // if we're dragging into a folder, put into a folder
                        if (viewSelector.level !== 'all') {
                           for (let i = 0; i < newSmall.length; i++) {
                              if (newSmall[i]._id === viewSelector.level) {
                                 for (let j = 0; j < newFiles.length; j++) {
                                    newSmall[i].files.unshift(newFiles[j])
                                 }
                              }
                           }
                           // otherwise, put in the list
                        } else {
                           for (let j = 0; j < newFiles.length; j++) {
                              newSmall.unshift(newFiles[j])
                           }
                        }
                        updateTiles(params.roomId, newSmall, tileListLarge);
                        let newString = {
                           'dataTile00': tileListLarge,
                           'dataTile01': newSmall
                        }
                        socket.emit(sceneUpdate, newString);
                     }
                  }).catch((e) => {
                     console.error(e)
                  })
               }
            }).catch((e) => {
               console.error(e)
            })
         }
      }
   }

   if (loading) {
      return (
         <Loading message={leaving ? 'Exiting' : 'Loading'} />
      );
   } else {
      // const tempStyle = () => {

      // }
      return (
         <>
            <div id="space" style={{
               'display': 'flex',
               flexDirection: 'row',
            }}>
               {
                  (process.env.REACT_APP_ENTITY === 'ndrc')
                     ? <NdrcPIP />
                     : ''
               }

               <DragDropContext onDragEnd={onDragEnd} style={{ width: '100px' }}>

                  <Cursor />
                  <div className="spaceEl flex-container-column" style={{
                     position: 'relative',
                     width: '100%',
                  }}>
                     <Header />
                     <div className="section-wrapper" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        'width': '100%',
                     }}>
                        <div
                           className="contentWrapper"
                           style={{
                              flexGrow: '1',
                              position: 'relative',
                           }}
                        >
                           <div
                              className={`${smallListShow ? `SmallPanelContainer` : `SmallPanelContainer collapse`} flex-container-row`}
                              onDragOver={fileDropDragOver}
                              onDragLeave={fileDropDragLeave}
                              onDrop={uploadFiles}>
                              <div
                                 style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    top: '-40px',
                                    left: '0px',
                                    width: '100%',
                                    // height: '100%',
                                    overflow: 'hidden',
                                 }}
                              >
                                 <Logo width={120} />
                              </div>

                              <div className="file-drop-image">
                                 <img src="/assets/file-drop.png" alt="" />
                              </div>
                              <TilePanelSmall
                                 videoSize={props.videoSize}
                                 accessLevel={accessLevel}
                                 tileList={tileListSmall}
                                 setTileList={setTileListSmall}
                                 tileListLarge={tileListLarge}
                                 sharing={sharing}
                                 smallListShow={smallListShow}
                                 pdfTracker={viewSelector.pdfTracker}
                                 onTransitionEnd={() => console.log('wtf')}
                              />
                              {accessLevel === 'owner' &&
                                 <SidebarToggle
                                    smallListShow={smallListShow}
                                    setSmallListShow={setSmallListShow}
                                 />
                              }
                           </div>

                           <TilePanelLarge
                              videoSize={props.videoSize}
                              accessLevel={accessLevel}
                              tileList={tileListLarge}
                              setTileList={setTileListLarge}
                              sharing={sharing}
                              pdfTracker={viewSelector.pdfTracker}
                              smallListShow={smallListShow}
                              annotationOpen={annotationOpen}
                              style={{
                                 top: '0',
                              }}
                           >
                           </TilePanelLarge>



                        </div>
                        <MattermostChat />
                        {/* <WebexVideo
                        jitsiKey={jitsiKey}
                        smallListShow={smallListShow}
                     /> */}
                        <JitsiVideo jitsiKey={jitsiKey} />
                        {/* <JitsiVideoMemo /> */}
                     </div>

                     <CallControlsFixed
                        setLoading={setLoading}
                        setLeaving={setLeaving}
                        audioPreMuted={audioPreMuted}
                        videoPreMuted={videoPreMuted}
                        setTileListSmall={setTileListSmall}
                        setTileListLarge={setTileListLarge}
                        tileListSmall={tileListSmall}
                        tileListLarge={tileListLarge}
                        setBlocking={setEndCallBlock}
                        endCallBlock={endCallBlock}
                        sharing={sharing}
                        selfVideoOn={selfVideoOn}
                        setSelfVideoOn={setSelfVideoOn}
                        accessLevel={accessLevel}
                        smallListShow={smallListShow}
                        annotationOpen={annotationOpen}
                        setAnnotationOpen={setAnnotationOpen}
                     />

                     {/* <WebexVideo selfVideoOn={false ?? selfVideoOn} /> */}
                  </div>
               </DragDropContext>
            </div>
         </>
      )
   }
}

const setupScreenShare = (sharing, tileListSmallRef, tileListLargeRef, roomId) => {
   if (sharing) {

      let newSmall = tileListSmallRef.current
      let newLarge = tileListLargeRef.current

      // check if exists
      let exists = false
      newLarge.forEach((val) => {
         if (val.fileName === 'screen-share') exists = true
      })
      newSmall.forEach((val) => {
         if (val.fileName === 'screen-share') exists = true
      })
      if (exists) return;

      // add screen share to top of list
      if (newLarge.length != 0) {
         let newSmallTop = newLarge.pop()
         newLarge.unshift({
            "_id": "1",
            "fileName": "screen-share"
         })
         newSmall.unshift(newSmallTop)
      } else {
         newLarge.push({
            "_id": "1",
            "fileName": "screen-share"
         })
      }

      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return
   } else {
      let newLarge = tileListLargeRef.current
      let newSmall = tileListSmallRef.current

      newLarge.forEach((val) => {
         if (val.fileName === 'screen-share') {
            // set top tile as newest large tile
            newLarge = []
            updateTiles(roomId, newSmall, newLarge)
            let newString = {
               'dataTile00': newLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            return
         }
      })

      let splice = undefined
      newSmall.forEach((val, index) => {
         if (val.fileName === 'screen-share') {
            splice = index
         }
      })

      if (splice !== undefined) newSmall.splice(splice, 1)
      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return;
   }
}

export const setupWhiteboard = (inUse, tileListSmallRef, tileListLargeRef, roomId) => {
   if (inUse) {
      console.log({ tileListLargeRef });
      // check if exists
      if (tileListLargeRef.current[0]?.fileName === 'whiteboard') return

      let newSmall = tileListSmallRef.current
      let newLarge = tileListLargeRef.current

      // check if exists
      let exists = false
      newSmall.forEach((val) => {
         if (val.fileName === 'whiteboard') exists = true
      })
      if (exists) return;

      // add whiteboard to top of list
      let newSmallTop = newLarge.pop()
      newLarge.unshift({
         "_id": uuidv4(),
         "fileName": "whiteboard"
      })
      newSmall.unshift(newSmallTop)

      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return
   } else {
      let newLarge = tileListLargeRef.current
      let newSmall = tileListSmallRef.current

      if (newLarge[0].fileName === 'whiteboard') {
         // set top tile as newest large tile
         newLarge = [newSmall[0]]
         newSmall.splice(0, 1)
         updateTiles(roomId, newSmall, newLarge)
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return
      }

      // the whiteboard should never end up in the small 
      // tile list...but if it somehow does....
      let splice = undefined
      newSmall.forEach((val, index) => {
         if (val.fileName === 'whiteboard') {
            splice = index
         }
      })

      if (splice !== undefined) newSmall.splice(splice, 1)
      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return;
   }
}

export const setupCalendar = (inUse, tileListSmallRef, tileListLargeRef, roomId) => {
   if (inUse) {

      // check if exists
      if (tileListLargeRef.current[0].fileName === 'calendar') return

      let newSmall = tileListSmallRef.current
      let newLarge = tileListLargeRef.current

      // check if exists
      let exists = false
      newSmall.forEach((val) => {
         if (val.fileName === 'calendar') exists = true
      })
      if (exists) return;

      // add calendar to top of list
      let newSmallTop = newLarge.pop()
      newLarge.unshift({
         "_id": uuidv4(),
         "fileName": "calendar"
      })
      newSmall.unshift(newSmallTop)

      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return
   } else {
      let newLarge = tileListLargeRef.current
      let newSmall = tileListSmallRef.current

      if (newLarge[0].fileName === 'calendar') {
         // set top tile as newest large tile
         newLarge = [newSmall[0]]
         newSmall.splice(0, 1)
         updateTiles(roomId, newSmall, newLarge)
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return
      }

      // the calendar should never end up in the small 
      // tile list...but if it somehow does....
      let splice = undefined
      newSmall.forEach((val, index) => {
         if (val.fileName === 'calendar') {
            splice = index
         }
      })

      if (splice !== undefined) newSmall.splice(splice, 1)
      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return;
   }
}

export const handleTileClose = (_id, tileListSmallRef, tileListLargeRef, teamId, roomId, level, dispatch) => {
   let newSmall = tileListSmallRef.current
   let newLarge = tileListLargeRef.current
   // check large tile list first
   if (newLarge.length > 0 && newLarge[0]._id === _id) {
      let largeEl = newLarge.pop()
      if (largeEl.old !== undefined) {
         let moved = false
         if (largeEl.old.level === 'all') {
            moved = true
            newSmall.splice(largeEl.old.index, 0, largeEl)
         } else {
            for (let i = 0; i < newSmall.length; i++) {
               if (newSmall[i]._id === largeEl.old.level) {
                  moved = true
                  newSmall[i].files.splice(largeEl.old.index, 0, largeEl)
               }
            }
            if (!moved) newSmall.unshift(largeEl)
         }
      } else {
         newSmall.unshift(largeEl)
      }
      updateTiles(roomId, newSmall, newLarge)
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return
   }
   // if we're in a folder
   if (level !== 'all') {
      for (let i = 0; i < newSmall.length; i++) {
         if (newSmall[i]._id === level) {
            for (let j = 0; newSmall[i].files.length; j++) {
               if (newSmall[i].files[j]._id === _id) {
                  let [removeEl] = newSmall[i].files.splice(j, 1)
                  // if we remove the last file from the folder
                  // remove folder and return to main list
                  if (newSmall[i].files.length === 0) {
                     dispatch(assignLevel('all'))
                     newSmall.splice(i, 1)
                  }
                  newSmall.unshift(removeEl)
                  updateTiles(roomId, newSmall, newLarge)
                  let newString = {
                     'dataTile00': newLarge,
                     'dataTile01': newSmall
                  }
                  socketGuestTokenRef.current.emit(sceneUpdate, newString);
                  return
               }
            }
         }
      }
   } else {
      for (let i = 0; i < newSmall.length; i++) {
         if (newSmall[i]._id === _id) {
            if (newSmall[i].fileName === 'folder') {
               let files = newSmall[i].files
               newSmall.splice(i, 1)
               for (let j = files.length - 1; j >= 0; j--) {
                  newSmall.splice(i, 0, files[j])
               }
            } else {
               if (!VALID_TILES.includes(newSmall[i].fileName)) unlinkAsset(teamId, roomId, newSmall[i].fileName)
               newSmall.splice(i, 1)
            }
            let newString = {
               'dataTile00': newLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            updateTiles(roomId, newSmall, newLarge)
            return
         }
      }
   }
}

export const createFolder = (source, combine, tileListSmall, tileListLarge, roomId) => {
   let newSmall = Array.from(tileListSmall)
   let sourceInd = source.index
   let combineInd
   for (let i = 0; i < newSmall.length; i++) {
      if (newSmall[i]._id === combine.draggableId) {
         // we don't want certain tiles to end up in folders
         if (FOLDER_TILES.includes(newSmall[i].fileName)) {
            queue.notify({
               title: 'Can not add tile to folder.'
            })
            return;
         }
         combineInd = i
         // if we're adding a file to a folder
         if (newSmall[i].fileName === 'folder') {
            let [dragEl] = newSmall.splice(sourceInd, 1)
            // need to adjust for the now-modified array due to .splice()
            sourceInd < combineInd ? combineInd-- : combineInd = combineInd
            newSmall[combineInd].files.push(dragEl)

            updateTiles(roomId, newSmall, tileListLarge);
            let newString = {
               'dataTile00': tileListLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            return;
         } else {
            let [dragEl] = newSmall.splice(sourceInd, 1)
            // need to adjust for the now-modified array due to .splice()
            sourceInd < combineInd ? combineInd-- : combineInd = combineInd
            // grab element we're turning intfo a folder
            let turnToFolderEl = newSmall[combineInd]
            // create new folder
            let newFolder = {
               _id: uuidv4(),
               fileName: 'folder',
               title: 'New Folder',
               files: [
                  turnToFolderEl,
                  dragEl
               ]
            }
            // replace element with folder
            newSmall.splice(combineInd, 1, newFolder)

            updateTiles(roomId, newSmall, tileListLarge);
            let newString = {
               'dataTile00': tileListLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            return;
         }
      }
   }
}

export const insideFolderMove = (source, destination, tileListSmall, tileListLarge, roomId, folderId) => {
   let ind = undefined;
   let newSmall = Array.from(tileListSmall)
   let newLarge = Array.from(tileListLarge)
   for (let i = 0; i < newSmall.length; i++) {
      if (newSmall[i]._id === folderId) ind = i
   }

   if (ind !== undefined) {
      if (source.droppableId === 'large-tile-list') {
         if (destination.droppableId === 'large-tile-list') return
         let [addToFolder] = newLarge.splice(source.index, 1)
         newSmall[ind].files.splice(destination.index, 0, addToFolder)

         updateTiles(roomId, newSmall, newLarge)
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return;
      } else {
         if (destination.droppableId === 'large-tile-list') {
            let [dragEl] = newSmall[ind].files.splice(source.index, 1)
            if (newLarge.length === 0) {
               newLarge.push(dragEl)
               newLarge = [...newLarge,]
               newLarge[0].old = { level: folderId, index: source.index }
            } else {
               let [largeEl] = newLarge.splice(0, 1);
               newLarge.push(dragEl)
               newLarge = [...newLarge,]
               newLarge[0].old = { level: folderId, index: source.index }

               if (largeEl.old !== undefined) {
                  if (largeEl.old.level === 'all') {
                     newSmall.splice(largeEl.old.index, 0, largeEl)
                  } else {
                     for (let i = 0; i < newSmall.length; i++) {
                        if (newSmall[i]._id === largeEl.old.level) {
                           newSmall[i].files.splice(largeEl.old.index, 0, largeEl)
                        }
                     }
                  }
               } else {
                  newSmall.unshift(largeEl)
               }
            }

            updateTiles(roomId, newSmall, newLarge)
            let newString = {
               'dataTile00': newLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            return;
         } else {
            let [dragEl] = newSmall[ind].files.splice(source.index, 1)
            newSmall[ind].files.splice(destination.index, 0, dragEl)

            updateTiles(roomId, newSmall, newLarge)
            let newString = {
               'dataTile00': newLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            return;
         }
      }
   } else {
      queue.notify({
         title: 'Error.'
      })
      return;
   }
}

export const dataTileMove = (source, destination, tileListSmall, tileListLarge, roomId, level, draggableId) => {
   if (!roomId) return
   if (destination.droppableId === "large-tile-list" && source.droppableId !== 'large-tile-list') {
      // no folders on large tile list
      if (tileListSmall[source.index].fileName === 'folder') {
         queue.notify({
            title: 'Can not put folder on main tile.'
         })
         return;
      }
      // if there is no large tile
      if (tileListLarge.length === 0) {
         let newSmall = tileListSmall
         let newLarge = newSmall.splice(source.index, 1)

         console.warn({ newSmall, newLarge });
         newLarge[0].old = { ...newLarge[0].old, level: level, index: source.index }

         updateTiles(roomId, newSmall, newLarge);
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return;
      }
      // disables dragging the entire utility tile to the large tile
      if (draggableId === '60d22fbd296626e420ade149') return

      // sends the calendar and whiteboard utility tiles to the large tile
      if (draggableId === 'util-draggable-0' && tileListLarge[0].fileName !== 'calendar') {
         let newSmall = tileListSmall
         let newLarge = [{
            _id: '012345',
            fileName: 'calendar'
         }]
         if (tileListLarge[0].fileName !== 'whiteboard') {
            newSmall.splice(destination.index + 1, 0, tileListLarge[0])
         }

         updateTiles(roomId, newSmall, newLarge);
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return;
      }
      if ((draggableId === 'util-draggable-1' && tileListLarge[0].fileName !== 'whiteboard') || (draggableId === 'utility-draggable-1' && tileListLarge[0].fileName !== 'whiteboard')) {
         let newSmall = tileListSmall
         let newLarge = [{
            _id: '123456',
            fileName: 'whiteboard'
         }]
         if (tileListLarge[0].fileName !== 'calendar') {
            newSmall.splice(destination.index + 1, 0, tileListLarge[0])
         }

         updateTiles(roomId, newSmall, newLarge);
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return;
      }
      // cannot drag the same utility tile twice
      if (draggableId === 'util-draggable-0' && tileListLarge[0].fileName === 'calendar') return
      if (draggableId === 'util-draggable-1' && tileListLarge[0].fileName === 'whiteboard') return

      let newLarge = tileListSmall.splice(source.index, 1)
      let newSmall = tileListSmall
      newSmall.splice(source.index, 0, tileListLarge[0])

      // remove calendar and whiteboard from small tile list
      newSmall.map((item, index) => {
         if (item.fileName === 'calendar' || item.fileName === 'whiteboard') {
            newSmall.splice(index, 1)
         }
      })

      updateTiles(roomId, newSmall, newLarge);
      let newString = {
         'dataTile00': newLarge,
         'dataTile01': newSmall
      }
      socketGuestTokenRef.current.emit(sceneUpdate, newString);
      return;
   }
   else if (destination.droppableId === "small-tile-list") {
      if (source.droppableId === 'large-tile-list') {
         if (tileListLarge[0].fileName === 'whiteboard') {
            let newSmall = tileListSmall
            let newLarge = newSmall.splice(source.index, 1)

            updateTiles(roomId, newSmall, newLarge);
            let newString = {
               'dataTile00': newLarge,
               'dataTile01': newSmall
            }
            socketGuestTokenRef.current.emit(sceneUpdate, newString);
            return;
         }
         let newLarge = Array.from(tileListLarge)
         newLarge.splice(source.index, 1)
         let newSmall = Array.from(tileListSmall)
         newSmall.splice(destination.index, 0, tileListLarge[0])

         updateTiles(roomId, newSmall, newLarge);
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);

         return;
      } else {
         let newSmall = Array.from(tileListSmall)
         let [move] = newSmall.splice(source.index, 1)
         newSmall.splice(destination.index, 0, move)

         updateTiles(roomId, newSmall, tileListLarge);
         let newString = {
            'dataTile00': tileListLarge,
            'dataTile01': newSmall
         }
         socketGuestTokenRef.current.emit(sceneUpdate, newString);
         return;
      }
   }
}
