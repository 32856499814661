// import string from './string';
// import { setAttrsRecursive } from '../index';

// export function create({ fill }) {
//    if (!string) return;
//    const color = fill || '#000000';
//    const parse = new DOMParser().parseFromString(string, 'text/html');
//    const el = parse.body.firstChild;

//    setAttrsRecursive(el, 'fill', color);

//    return {
//       string,
//       el,
//    };
// }

export const string = `
   <svg height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg" style="stroke-width: 0.5">
      <path
         d="m18 9.75c-.828 0-1.5.672-1.5 1.5v-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v-6c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v11.25l-3.717-2.28h-.003c-.22-.14-.49-.22-.78-.22-.83 0-1.5.67-1.5 1.5 0 .29.08.57.23.8l-.002-.004 4.596 7.329c.732 1.167 2.012 1.875 3.389 1.875h4.787c2.761 0 5-2.239 5-5v-7.25c0-.828-.672-1.5-1.5-1.5z"
         fill="#000000"
      />
      <path d="m7.25 15c-.414 0-.75-.336-.75-.75v-11c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v10c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-10c0-.413-.336-.75-.75-.75s-.75.337-.75.75v11c0 .414-.336.75-.75.75z" />
      <path d="m13.25 14c-.414 0-.75-.336-.75-.75v-4c0-.413-.336-.75-.75-.75s-.75.337-.75.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v4c0 .414-.336.75-.75.75z" />
      <path d="m16.25 14c-.414 0-.75-.336-.75-.75v-3c0-.413-.336-.75-.75-.75s-.75.337-.75.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v3c0 .414-.336.75-.75.75z" />
      <path d="m15.25 24h-5.517c-1.635 0-3.184-.864-4.043-2.257l-4.335-7.029c-.001-.002-.001-.003-.002-.004-.231-.353-.353-.772-.353-1.21 0-1.24 1.009-2.25 2.25-2.25.413 0 .808.108 1.147.314.012.007.024.015.037.021l3.217 2.029c.349.223.454.686.233 1.036s-.685.454-1.035.234l-3.188-2.012c-.012-.007-.023-.014-.034-.021-.104-.065-.238-.101-.377-.101-.414 0-.75.337-.75.75 0 .138.035.269.097.373.007.01.013.02.019.029l4.35 7.054c.588.952 1.648 1.544 2.767 1.544h5.517c1.792 0 3.25-1.458 3.25-3.25v-8c0-.413-.336-.75-.75-.75s-.75.337-.75.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v8c0 2.619-2.131 4.75-4.75 4.75z" />
   </svg>;
`;

export default string;
