import { SocketContext } from "context/socket"
import { updateAssetName, updateVirtualAssetName } from "net/assets"
import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useContext } from "react"
import FullScreenButton from "buttons/FullScreenButton"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Tooltip } from '@rmwc/tooltip';
import UnmountButton from "buttons/UnmountButton"

export default function TileHeader(props) {
   const socket = useContext(SocketContext)
   const closeTile = 'close-tile'
   const sceneUpdate = 'scene-string'
   const viewSelector = useSelector(state => state.view)
   const viewLevel = viewSelector.level;
   const fullScreen = useSelector(state => state.view.fullScreen)
   const params = useParams()

   const propsRef = useRef(props)

   const [fileName, setFileName] = useState(props.title)
   const [editing, setEditing] = useState(false)
   const editingRef = useRef(editing)

   const tileId = props.id;

   const handleClick = () => {
      // if (!viewFullScreen) socket.emit(closeTile, { _id: props.id, folder: viewSelector.level })
      if (fullScreen) socket.emit('large-tile-fullscreen', false);
      socket.emit(closeTile, { _id: tileId, folder: viewLevel })
   }

   const titleDoubleClick = () => {
      if (props.edit && props.accessLevel === 'owner') setEditing(true)
   }

   const checkForSubmit = (e) => {
      if ((e.code === 'Enter' || e.target.id !== 'edit-tile-name') && editingRef.current) {
         let el = document.getElementById('edit-tile-name')
         if (el) {
            if (el.value === "") {
               setFileName(propsRef.current.title)
            } else {
               let newName = el.value
               if (props.virtual) {
                  updateVirtualAssetName(params.roomId, propsRef.current.id, newName).then((res) => {
                     socket.emit(sceneUpdate, res.value.scene2d)
                  }).catch(() => {
                     setFileName(propsRef.current.title)
                  })
               } else {
                  updateAssetName(propsRef.current.teamId, propsRef.current.roomId, propsRef.current.fileName, newName).then((res) => {
                     socket.emit(sceneUpdate, res.value.scene2d)
                  }).catch(() => {
                     setFileName(propsRef.current.title)
                  })
               }
               setFileName(el.value)
            }
            setEditing(false)
         }
      }
   }

   const handleChange = (e) => {
      setFileName(e.target.value)
   }

   useEffect(() => {
      editingRef.current = editing
      propsRef.current = props
   })

   useEffect(() => {
      if (document.getElementById('edit-tile-name')) {
         checkForSubmit({ code: 'Enter' })
      }
   }, [props.dragging])

   useEffect(() => {
      setFileName(props.title)
   }, [props.title])

   useEffect(() => {
      document.addEventListener('click', checkForSubmit)
      document.addEventListener('keydown', checkForSubmit)

      return () => {
         document.removeEventListener('click', checkForSubmit)
         document.removeEventListener('keydown', checkForSubmit)
      }
   }, [])

   const isLarge = props.large;

   return (
      <>
         {/* { isLarge ? 'large' : 'small' } */}
         <div 
            className={`flex-container-row defaultTileHeader ${props.accessLevel === 'owner' ? 'owner' : 'guest'}`}
         >
            {/* {props.accessLevel === 'owner' &&
               <div style={{ width: '33px' }} />
            } */}
            <div 
               className="flex-container-row center overflow-hide" 
               style={{width: '100%'}}
            >
               {
                  (editing && !props.dragging) && 
                     <input 
                        id="edit-tile-name" 
                        value={fileName} 
                        onChange={handleChange} 
                        minLength="1" 
                        autoFocus 
                        inputMode="text" 
                     />
               }
               {
                  (!editing || props.dragging) && 
                     <p 
                        className="dataTileTitle"
                        onDoubleClick={titleDoubleClick}
                     >{fileName}</p>
               }
            </div>
            {props.accessLevel === 'owner' &&
               <div className="flex-container-row flex-end headerPadding tile-header-btns-wrapper">
                  {
                     isLarge &&
                        <FullScreenButton />
                  }
                  {/* <button className="tileClose btn" onClick={handleClick}>
                     <Tooltip content="Unmount">
                        <span className="material-icons">clear</span>
                     </Tooltip>
                  </button> */}
                  <UnmountButton {...props} />
                  {/* <UnmountButton {...props} handleClick={handleClick} /> */}
               </div>
            }
         </div>
      </>
   )
}