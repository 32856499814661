import React, { useLayoutEffect, useState } from 'react';

// from https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
export function useWindowSize() {
   const [size, setSize] = useState([0, 0]);
   useLayoutEffect(() => {
      function updateSize() {
         // setSize([window.innerWidth, window.innerHeight]);
         const width = window.innerWidth;
         const height = window.innerHeight;
         const type = width > height ? 'landscape' : 'portrait';
         setSize({ width, height, type });
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
   }, []);
   return size;
}
