// CSS Color Names
// Compiled by @bobspace.
//
// A javascript array containing all of the color names listed in the CSS Spec.
// The full list can be found here: https://www.w3schools.com/cssref/css_colors.asp
// Use it as you please, 'cuz you can't, like, own a color, man.

export const CSS_COLOR_NAMES = [
   'Blue',
   'BlueViolet',
   'Brown',
   'CadetBlue',
   'Chocolate',
   'Coral',
   'CornflowerBlue',
   'Crimson',
   'DarkBlue',
   'FireBrick',
   'ForestGreen',
   'Fuchsia',
   'GoldenRod',
   'Green',
   'IndianRed',
   'Indigo',
   'Magenta',
   'Maroon',
   'Navy',
   'Olive',
   'Orange',
   'Orchid',
   'Peru',
   'Purple',
   'RebeccaPurple',
   'Red',
   'RoyalBlue',
   'SaddleBrown',
   'SeaGreen',
   'Sienna',
   'SteelBlue',
   'Teal',
   'Tomato',
];

export const CSS_COLOR_HEX = [
   '#0000ff',
   '#8a2be2',
   '#a52a2a',
   '#5f9ea0',
   '#d2691e',
   '#ff7f50',
   '#6495ed',
   '#dc143c',
   '#00008b',
   '#b22222',
   '#228b22',
   '#ff00ff',
   '#daa520',
   '#008000',
   '#cd5c5c',
   '#4b0082',
   '#ff00ff',
   '#800000',
   '#000080',
   '#808000',
   '#ffa500',
   '#da70d6',
   '#cd853f',
   '#800080',
   '#663399',
   '#ff0000',
   '#4169e1',
   '#8b4513',
   '#2e8b57',
   '#a0522d',
   '#4682b4',
   '#008080',
   '#ff6347',
];

// function getHexColor(cssColorName) {
//    let dummyElement = document.createElement('div');
//    dummyElement.style.color = cssColorName;
//    document.body.appendChild(dummyElement);

//    let computedColor = window.getComputedStyle(dummyElement).color;
//    document.body.removeChild(dummyElement);

//    let rgbMatch = computedColor.match(/rgba?\((\d+), (\d+), (\d+)/);

//    if (rgbMatch) {
//      let [, r, g, b] = rgbMatch.map(Number);
//      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
//    }

//    return null;
// }

// let hexColors = CSS_COLOR_NAMES.map(getHexColor);

// console.log(hexColors);
