// import string from './string';
// import { setAttrsRecursive } from '../index';

// export function create({ fill }) {
//    if (!string) return;
//    const color = fill || '#000000';
//    const parse = new DOMParser().parseFromString(string, 'text/html');
//    const el = parse.body.firstChild;

//    setAttrsRecursive(el, 'fill', color);

//    return {
//       string,
//       el,
//    };
// }

export const string = `
   <svg
      viewBox="0 0 11 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
   >
      <defs>
         <path
            id="path-1"
            d="M0,9 C0,13.971 4.03,18 9,18 L9,18 C13.97,18 18,13.971 18,9 L18,9 C18,4.029 13.97,0 9,0 L9,0 C4.03,0 0,4.029 0,9 L0,9 Z"
         ></path>
      </defs>
      <path
         d="M0,0 L0,11.188 L2.969,8.322 L3.129,8.183 L8.165,8.183 L0,0 Z"
         id="cursor"
         fill="#000000"
      ></path>
   </svg>
`;

export default string;
