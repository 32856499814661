import { useEffect, useRef, useContext, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from '../../context/socket';
import { CSS_COLOR_NAMES } from 'aesthetic/css_colors'
import { modifyCursor } from 'app/participants';

const randomColor = CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)]

export default function VNCTileIframe({ id, src, large }) {
   // const isLarge = props.large;
   const [cobrowseInited, setCobrowseInited] = useState(false);
   const [isLarge, setIsLarge] = useState(large);

   const dispatch = useDispatch();
   // const { id, src } = props;
   const socket = useContext(SocketContext);
   const iframeElId = `cobrowse_iframe__${id}`;
   const iframeRef = useRef(null);
   const room = useSelector(state => state.room.data);
   const user = useSelector(state => state.user)
   // const $participants = useSelector(state => state.participants);
   const cursors = useSelector(state => state.participants.cursors);
   const cursorsRef = useRef(cursors)
   const iframeWrapperRef = useRef(null);
   // const userData = {
   // Causing error because of InputDeviceInfo could not be cloned.
   //    ...user,
   //    color: user.cursorColor || randomColor,
   // }
   const userData = {
      _id: user._id,
      email: user.email,
      name: user.name,
      guest: user.guest,
      owner: user.owner,
      cursorColor: user.cursorColor,
      displayName: user.displayName,
      audioPreMuted: user.audioPreMuted, // Needed?
      videoPreMuted: user.videoPreMuted, // Needed?

      // Added //
      color: user.cursorColor || randomColor,
   };
   const messagePayload = {
      user: userData,
      room,
      password: 'password', // Can be any value but I'm thinking to set this as room._id (implementation also needed from api-server).
   }
   const isGuest = user.guest;

   function initCobrowse() {
      log('<initCobrowse()>: Triggered on iframe load');
      // if (isGuest) return log('User is guest. Not initializing cobrowse.', 'warn');
      if (isGuest) {
         log(`User is guest -- Do we allow guest to interact with cobrowse???`, 'warn');
      }
      if (!large) {
         log('<initCobrowse()>: Not initializing cobrowse because tile is not large.', 'warn');
         if (cobrowseInited) return termCobrowse();
         return;
      }
      const iframeEl = iframeRef.current;
      const iframeWindow = iframeEl.contentWindow;

      const data = {
         type: 'init:cobrowse',
         payload: messagePayload,
      }
      log(`<initCobrowse()>: postMessage to iframe`, 'info', { data, iframeWindow });
      iframeWindow.postMessage(data, iframeWindow.origin);
      // iframeWindow.postMessage(data, '*');
      log(`<initCobrowse()>: Okay now we wait for response from cobrowse instance`, 'info', { data, iframeWindow });

      //!! TEMP !!//
      // let countDown = 5000;
      // let intervalMs = 1000;
      // window.___interval = setInterval(() => {
      //    if (cobrowseInited) {
      //       log(`Cobrowse initialized successfully!`, 'info');
      //       clearInterval(window.___interval);
      //       return;
      //    }
      //    if (countDown <= 0) {
      //       log(`Cobrowse failed to initialize.. Triggering initCobrowse() again..`, 'error');
      //       clearInterval(window.___interval);
      //       initCobrowse();
      //       return;
      //    }
      //    log(`Waiting for cobrowse to initialize.. ${countDown / 1000} seconds remaining..`, 'info');
      //    countDown -= intervalMs;
      // }, intervalMs);
   }

   function termCobrowse() {
      log('termCobrowse()');
      const iframeEl = iframeRef.current;
      const iframeWindow = iframeEl.contentWindow;

      const data = {
         type: 'term:cobrowse',
         payload: messagePayload,
      }
      iframeWindow.postMessage(data, iframeWindow.origin);
   }

   function messageListener(evt) {
      const type = evt.data.type;
      // const payload = evt.data.payload;
      if (type === 'init:cobrowse:succeeded') {
         log(`messageListener() -- init:cobrowse:succeeded`);
         setCobrowseInited(true);
         console.info('Cobrowse initialized successfully');
         return;
      }
      if (type === 'init:cobrowse:failed') {
         log(`messageListener() -- init:cobrowse:failed`);
         setCobrowseInited(false);
         const delay = 5000;
         const msg = `Cobrowse failed to initialize.. Re-trying in ${delay / 1000} seconds..`;
         console.error(msg);
         setTimeout(messageListener, delay);
         return;
      }

      if (type === 'term:cobrowse:succeeded') {
         log(`messageListener() -- term:cobrowse:succeeded`);
         setCobrowseInited(false);
         toggleCursorVisibility(null, true);
         console.info('Cobrowse terminated successfully');
         return;
      }
   }

   function toggleCursorVisibility(pid, show) {
      console.warn('toggleCursorVisibility()', pid, show);
      //>> No pid means show/hide all cursors (default show all) <<//
      if (!pid) {
         // const cursors = Object.values($participants.cursors);
         const cursorsArr = Object.values(cursorsRef.current);
         cursorsArr.forEach((cur) => {
            const pid = cur._id;
            // const cursorId = `cursor_${pid}`;
            const cursorId = getCursorId(pid);
            const cursorEl = document.querySelector(`#${cursorId}`);
            if (!cursorEl) return;
            //>> Unless intentionally hide all cursors, show all cursors <<//
            if (show !== false) show = true;
            cursorEl.style.display = show ? 'block' : 'none';
         });
         return;
      }
      // const cursorId = `cursor_${pid}`;
      const cursorId = getCursorId(pid);
      const cursorEl = document.querySelector(`#${cursorId}`);
      if (!cursorEl) return;
      cursorEl.style.display = show ? 'block' : 'none';
      dispatch(modifyCursor({ _id: pid, show }));

      // console.warn('DONT MOVE! dispatching modifyCursor() in 1000ms!!!');
      // setTimeout(() => {
      //    dispatch(modifyCursor({ _id: pid, show }));
      // }, 1000);
   }

   function onMouseEnter() {
      //@@ Emit socket event to hide theia cobrowse cursor @@//
      console.warn('onMouseEnter', userData);
      socket.emit('hide-cursor', {
         _id: userData._id,
         // show: false,
      });
   }

   function onMouseLeave(evt) {
      //@@ Emit socket event to show theia cobrowse cursor @@//
      console.warn('onMouseLeave', userData);
      socket.emit('show-cursor', {
         _id: userData._id,
         // userId: userData._id,
      });
   }

   function onWindowResize(evt) {
      const cursors = cursorsRef.current;
      log('>>>>> onWindowResize() <<<<<', 'info', { cursors });
      if (!cursors || Object.values(cursors).length < 1) {
         log(`cursors is null or empty`, 'warn', { cursors });
         return;
      }

      const cursorsArr = Object.values(cursors);
      cursorsArr.forEach((cur) => {
         if (!cur.show) {
            // Going to trust that element is also hidden.
            log(`Cursor ${getCursorId(cur.id)} should already be hidden`, 'info', { cur });
            return;
         };
         const pid = cur._id || cur.id;
         if (!pid) return;
         const cursorId = getCursorId(pid);
         const cursorEl = document.querySelector(`#${cursorId}`);
         const iframeEl = iframeRef.current;
         if (!cursorEl || !iframeEl) {
            log(`onWindowResize() -- cursorEl or iframeEl is null`, 'error', { cursorId });
            return;
         }

         // Check if cursor is on top of iframe.
         const cursorElRect = cursorEl.getBoundingClientRect();
         const { top: topc, left: leftc, width: widthc, height: heightc } = cursorElRect;
         const bottomc = topc + heightc;
         const rightc = leftc + widthc;

         const iframeElRect = iframeEl.getBoundingClientRect();
         const { top: topi, left: lefti, width: widthi, height: heighti } = iframeElRect;
         const bottomi = topi + heighti;
         const righti = lefti + widthi;

         log(`Comparing cursorElRect to iframeElRect`, 'info', { cursorElRect, iframeElRect });

         // If cursor is on top of iframe, hide it.
         if (topc >= topi && leftc >= lefti && bottomc <= bottomi && rightc <= righti) {
            cursorEl.style.visibility = 'hidden';
         }
      });

   }

   useEffect(() => {
      cursorsRef.current = cursors;
   }, [cursors]);

   useEffect(() => {
      log(`Component mounted!!!`);

      if (!large) return;

      window.addEventListener('message', messageListener);
      window.addEventListener('resize', onWindowResize);
      socket.on('hide-cursor', (payload) => {
         console.warn('socket on hide-cursor', payload);
         const participantId = payload._id;
         if (!participantId || participantId === userData._id) return;
         toggleCursorVisibility(participantId, false)
      });
      socket.on('show-cursor', (payload) => {
         console.warn('socket on show-cursor', payload);
         const participantId = payload._id;
         if (!participantId || participantId === userData._id) return;
         toggleCursorVisibility(participantId, true)
      });

      return () => {
         termCobrowse();
         window.removeEventListener('message', messageListener);
         window.removeEventListener('resize', onWindowResize);
         socket.off('hide-cursor');
         socket.off('show-cursor');
      }
   }, []);

   return (
      <div
         ref={iframeWrapperRef}
         className='iframe-wrapper'
         style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            padding: 0,
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
         }}
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         <div
            className='iframe-guest-blocker'
            style={{
               display: isGuest ? 'block' : 'none',
               cursor: 'not-allowed',
               position: 'absolute',
               top: 0,
               left: 0,
               height: '100%',
               width: '100%',
               backgroundColor: 'rgba(0,0,0,0)',
               zIndex: 5,
            }}
         ></div>
         {/* <div
            className='iframe-loader'
            style={{
               display: cobrowseInited ? 'none' : 'block',
               position: 'absolute',
               top: 0,
               left: 0,
               height: '100%',
               width: '100%',
               backgroundColor: 'rgba(0,0,0)',
               zIndex: 2,
               pointerEvents: 'none',
            }}
         >Initializing...</div> */}
         <iframe
            ref={iframeRef}
            id={iframeElId}
            src={src}
            height={'100%'}
            width={'100%'}
            title={'vnc iframe title'}
            allowFullScreen
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            onLoad={initCobrowse}
            style={{
               border: 'none',
            }}
         />
      </div>
   );
}

function log(msg, type = 'info', data) {
   const logStyles = {
      info: `background:black;color:#87ceeb;border:1px solid #87ceeb;font-size:18px;padding:10px;`,
      error: `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`,
      warn: `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`,
   }
   if (data) return console.log(`%c[theia]: ${msg}`, logStyles[type], data);
   return console.log(`%c[theia]: ${msg}`, logStyles[type]);
}

function getCursorId(pid) {
   return `cursor_${pid}`;
}