import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

export default function Test() {
   const [referenceElement, setReferenceElement] = useState(null);
   const [popperElement, setPopperElement] = useState(null);
   const [arrowElement, setArrowElement] = useState(null);
   const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
      placement: 'right'
   });

   useEffect(() => {
      const handleMouseMove = (e) => {
         if (!referenceElement) return;

         referenceElement.style.left = `${e.clientX}px`;
         referenceElement.style.top = `${e.clientY}px`;

         if (update) {
            update();
         }
      };

      document.addEventListener('mousemove', handleMouseMove);

      return () => {
         document.removeEventListener('mousemove', handleMouseMove);
      };
   }, [referenceElement, update]);

   return (
      <div
         style={{
            position: 'absolute',
            zIndex: 1000,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
         }}
      >
         <div
            ref={setReferenceElement}
            style={{
               position: 'absolute',
               zIndex: 1001,
               backgroundColor: 'red',
               width: '20px',
               height: '20px',
            }}
         >
         </div>

         <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            Tooltip
            <div ref={setArrowElement} style={styles.arrow} />
         </div>
      </div>
   );
}
