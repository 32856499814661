export default function ArrowCursor(props) {
   const fill = props?.fill || props?.color;
   const width = props?.width || props?.size;
   const height = props?.height || props?.size;

   // if (!fill || !width || !height) {
   //    throw new Error('ArrowCursor: missing required props', { fill, width, height });
   // }

   return (
      <svg
         viewBox="0 0 11 11"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         height={height}
         width={width}
         stroke-width="0.5"
         stroke="#000000"
      >
         <defs>
            <path
               id="path-1"
               d="M0,9 C0,13.971 4.03,18 9,18 L9,18 C13.97,18 18,13.971 18,9 L18,9 C18,4.029 13.97,0 9,0 L9,0 C4.03,0 0,4.029 0,9 L0,9 Z"
            ></path>
         </defs>
         <path
            d="M0,0 L0,11.188 L2.969,8.322 L3.129,8.183 L8.165,8.183 L0,0 Z"
            id="cursor"
            fill={fill}
         ></path>
      </svg>
   );
}