import { createSlice } from '@reduxjs/toolkit';

export const guestSlice = createSlice({
   name: 'env',
   initialState: {
      _minDisplayNameLength: 1, // Minimum length of the guest's display name.
      displayName: null, // Display name of the guest.
      displayNameInput: null, // Input field for the guest's display name in preview screen.
   },
   reducers: {
      updateGuestDisplayName(state, action) {
         return {
            ...state,
            displayName: action.payload,
         };
      },
      updateGuestDisplayNameInput(state, action) {
         return {
            ...state,
            displayNameInput: action.payload,
         };
      },
   },
});

export const { updateGuestDisplayName, updateGuestDisplayNameInput } = guestSlice.actions;
export default guestSlice.reducer;
