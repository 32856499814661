/**
 * Video element holding your large
 * scale video preview prior to entering the space
 */
import { Button } from '@rmwc/button'
import AudioSelection from 'buttons/video/AudioSelection';
import JoinSpaceButton from 'buttons/JoinSpaceButton';
import PreviewMuteAudioButton from 'buttons/video/PreviewMuteAudioButton';
import PreviewMuteVideoButton from 'buttons/video/PreviewMuteVideoButton';
import VideoSelection from 'buttons/video/VideoSelection';
import { getUserMedia } from 'js/webex-controls';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { assignAudioDevice, assignDevices, assignVideoDevice } from 'app/user';

import GuestLogin from './GuestLogin';
import { TextField } from '@rmwc/textfield';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { updateGuestDisplayNameInput } from 'app/guest';
import { useWindowSize } from 'hooks/useWindowSize';


export default function VideoPreview(props) {
   const self_view_preview = useRef(null);
   const dispatch = useDispatch()
   const [videoInit, setVideoInit] = useState(false)
   const [validInput, setValidInput] = useState(true);

   const nameInput = useRef(null);
   const guestDisplayName = useSelector(state => state.guest.displayName)
   const videoDevice = useSelector(state => state.user.videoDevice)


   useEffect(() => {
      getUserMedia({ audio: true, video: true }).then((mediaStream) => {

         const isValidMediaStream = mediaStream && !mediaStream.error;

         if (!isValidMediaStream) {
            const mediaError = mediaStream;
            dispatch(assignVideoDevice(mediaError))
            setVideoInit(false)
            return;
         }

         const videoDevice = mediaStream.getVideoTracks()[0];
         const audioDevice = mediaStream.getAudioTracks()[0];
         const videoSettings = videoDevice.getSettings();
         const audioSettings = audioDevice.getSettings();
         const videoState = {
            label: videoDevice.label,
            ...videoSettings
         };
         const audioState = {
            label: audioDevice.label,
            ...audioSettings
         };
         dispatch(assignVideoDevice(videoState));
         dispatch(assignAudioDevice(audioState));

         // setVideoInit(document.getElementById('self-view-preview').srcObject !== null || mediaStream === undefined)
         setVideoInit(true)

         navigator.mediaDevices.enumerateDevices().then((devices) => {
            dispatch(assignDevices(devices))
         })
      })

   }, [])

   useEffect(() => {
      const selfVideoPreviewEl = self_view_preview.current;
      if (!selfVideoPreviewEl) return;
      selfVideoPreviewEl.addEventListener('loadeddata', (evt) => {
         console.log('video content loaded!');
      });
   }, []);


   const _minDisplayNameLength = useSelector(state => state.guest._minDisplayNameLength);
   function guestNameOnInput(evt) {
      const inputValue = evt.target.value;
      dispatch(updateGuestDisplayNameInput(inputValue))
      if (!inputValue || inputValue.length < _minDisplayNameLength) {
         setValidInput(false);
         return;
      }
      setValidInput(true);
   }
   const isValidGuestName = validInput;

   const isLoggedInUser = !!props.isUserLogin;

   const audioPreMuted = props.audioPreMuted;
   const videoPreMuted = props.videoPreMuted;
   const showDeviceError = audioPreMuted && videoPreMuted;

   const videoWidth = useSelector(state => state.user.videoDevice.width);
   const videoHeight = useSelector(state => state.user.videoDevice.height);
   const videoAspectRatio = `${videoWidth}/${videoHeight}`

   const BOTTOM_BAR_HEIGHT = 45;
   const DARK_BG = '#343434';

   const getVideoSize = () => {
      const videoEl = self_view_preview.current;
      if (!videoEl) return;
      const rect = videoEl.getBoundingClientRect();
      return rect;
   }


   useEffect(() => {
      async function getMedia() {
         try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            self_view_preview.current.srcObject = stream;

            // Recalculate variables once the video is ready to play
            self_view_preview.current.onloadedmetadata = () => {
               console.log('Video dimensions:', self_view_preview.current.videoWidth, self_view_preview.current.videoHeight);
               // recalculate your variables here
            };

         } catch (err) {
            console.error('Error accessing media devices.', err);
         }
      }

      getMedia();

      // Clean up
      return () => {
         if (self_view_preview.current && self_view_preview.current.srcObject) {
            let tracks = self_view_preview.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            self_view_preview.current.srcObject = null;
         }
      };
   }, []);


   const windowSizes = useWindowSize();
   const { width, height, type } = windowSizes;
   const videoStyleDynamic = {
      width: type === 'landscape' ? 'auto' : '100%',
      height: type === 'landscape' ? '100%' : 'auto',
   };
   const videoWrapperStyleDynamic = {
      background: videoPreMuted || videoDevice.error ? DARK_BG : 'transparent',
      width: videoPreMuted ? '80%' : 'auto',
      minHeight: type === 'landscape' ? '80%' : 'auto',
      minWidth: type === 'landscape' ? 'max-content' : '80%',
   };
   const videoParentStyleDynamic = {
      // width: type === 'landscape' ? (getVideoSize()?.width + 'px' || 'auto')  : '100%',
      width: (() => {
         if (videoPreMuted) return '100%';
         return type === 'landscape' ? (getVideoSize()?.width + 'px' || 'auto') : '100%';
      })(),
   }

   return (
      <div className='preview-cam-wrapper' key={videoPreMuted}>
         <div
            className='preview-cam-wrapper__content'
            style={{
               ...videoWrapperStyleDynamic,
               // background: 'transparent',
               maxHeight: '100%',
            }}
         >
            <div
               style={{
                  display: showDeviceError ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  background: 'black',
                  color: 'red',
                  fontFamily: 'Roboto, sans-serif',
               }}
            >Please enable Camera or Microphone to Join Space</div>
            <div
               className='preview-cam'
               style={{
                  flexGrow: 1,
                  maxHeight: `calc(100% - ${BOTTOM_BAR_HEIGHT}px)`,
                  background: 'transparent',
                  ...videoParentStyleDynamic,
               }}
            >
               <video
                  id="self-view-preview"
                  ref={self_view_preview}
                  autoPlay
                  muted
                  style={{
                     objectFit: 'contain',
                     // border: '1px solid red',
                     ...videoStyleDynamic,
                     // maxHeight: 'calc(100vh - 100px)',
                     aspectRatio: videoAspectRatio,
                     maxWidth: '100vw',
                  }}
               ></video>
            </div>
            <div
               style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  height: `${BOTTOM_BAR_HEIGHT}px`,
                  background: DARK_BG,
               }}
            >
               <div className='preview-cam-wrapper__action'>
                  <div
                     style={{
                        display: !isLoggedInUser ? 'block' : 'none',
                        // height: '36px',
                        height: '100%',
                        background: 'green',
                     }}
                  >
                     <TextField
                        label="Enter Display Name"
                        required
                        inputRef={nameInput}
                        invalid={!validInput}
                        onInput={guestNameOnInput}
                        style={{
                           height: BOTTOM_BAR_HEIGHT + 'px',
                           width: 'max-content',
                           // maxHeight: '48px',
                        }}
                     />
                  </div>

                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                     }}
                  >
                     <div className='preview-button-group button-group'>
                        <PreviewMuteVideoButton setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} />
                        <VideoSelection setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} audioPreMuted={props.audioPreMuted} />
                     </div>

                     <div className='preview-button-group button-group'>
                        <PreviewMuteAudioButton setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} />
                        <AudioSelection setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} videoPreMuted={props.videoPreMuted} />
                     </div>
                  </div>
               </div>

               <div
                  style={{
                     display: isLoggedInUser ? 'none' : 'flex',
                     width: 'max-content',
                     flexGrow: 1,
                  }}
               >
                  <GuestLogin
                     validInput={validInput}
                     setValidInput={setValidInput}
                     nameInput={nameInput}
                     audioPreMuted={props.audioPreMuted}
                     videoPreMuted={props.videoPreMuted}
                     videoInit={videoInit}
                     setLoading={props.setLoading}
                     setAdmitMessage={props.setAdmitMessage}
                     showLogin={true}
                  />
               </div>
               <div
                  style={{
                     display: isLoggedInUser ? 'flex' : 'none',
                     width: 'max-content',
                     flexGrow: 1,
                  }}
               >
                  <JoinSpaceButton
                     roomId={props.roomId}
                     audioPreMuted={props.audioPreMuted}
                     videoPreMuted={props.videoPreMuted}
                     videoInit={videoInit}
                  />
               </div>
            </div>
         </div>

      </div>
      // <>
      //    <div className="previewControlWrapper flex-container-row">
      //       <div className="resposiveControlBar">
      //          <div className="flex-container-row flex-between">
      //             <div className="lobby full-width flex-container-row">
      //                <div className="buttonBox Float-center">
      //                   {
      //                      !isLoggedInUser &&
      //                      <TextField className="guestLoginField" label="Enter Display Name" required inputRef={nameInput} invalid={!validInput} onInput={guestNameOnInput} />
      //                   }
      //                   <ul className="button-group">
      //                      <li><PreviewMuteVideoButton setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} /></li>
      //                      <li><VideoSelection setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} audioPreMuted={props.audioPreMuted} /></li>
      //                   </ul>
      //                   <ul className="button-group">
      //                      <li><PreviewMuteAudioButton setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} /></li>
      //                      <li><AudioSelection setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} videoPreMuted={props.videoPreMuted} /></li>
      //                   </ul>
      //                </div>
      //                {
      //                   (isLoggedInUser)
      //                      ? <JoinSpaceButton
      //                         roomId={props.roomId}
      //                         audioPreMuted={props.audioPreMuted}
      //                         videoPreMuted={props.videoPreMuted}
      //                         videoInit={videoInit}
      //                      />
      //                      : <GuestLogin
      //                         validInput={validInput}
      //                         setValidInput={setValidInput}
      //                         nameInput={nameInput}
      //                         audioPreMuted={props.audioPreMuted}
      //                         videoPreMuted={props.videoPreMuted}
      //                         videoInit={videoInit}
      //                         setLoading={props.setLoading}
      //                         setAdmitMessage={props.setAdmitMessage}
      //                      />
      //                }
      //             </div>
      //          </div>
      //       </div>
      //    </div>
      // </>
   )
}
