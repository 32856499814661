// import { createContext } from 'react';
// import io from 'socket.io-client'
// const baseSocketUrl = window._env_.REACT_APP_SOCKET_SERVER;
// const roomId = window.location.pathname.split('/')[2]

// export const socketConnection = io(baseSocketUrl + "/space-" + roomId, {transports: [ "websocket" ]});
// export const SocketContext = createContext()

import { createContext } from 'react';
import io from 'socket.io-client';
const roomId = window.location.pathname.split('/')[2];
const baseSocketUrl = window._env_.REACT_APP_SOCKET_SERVER;
const subPath = window._env_.REACT_APP_SOCKET_SUBPATH || '';
const roomPath = `/space-${roomId}`;
const socketURL = baseSocketUrl + roomPath;

export const socketConnection = io.connect(socketURL, {
   path: subPath,
   transports: ['websocket', 'polling'],
});

socketConnection.on('connect', () => {
   const logStyle = `background:black;color:white;border:1px solid white;font-size:18px;padding:10px;`;
   console.log(`%c[connect] | Socket Connected:\n - URL: ${socketURL}\n - PATH: ${subPath}`, logStyle);
});

socketConnection.on('connect_error', () => {
   const logStyle = `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;width:100%;`;
   console.log(`%c[connect_error] | Socket Connection Error:\n - URL: ${socketURL}\n - PATH: ${subPath}`, logStyle);
});

socketConnection.io.on("reconnection_attempt", () => {
   const logStyle = `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`;
   console.log(`%c[reconnection_attempt] | Socket Reconnect Attempt:\n - URL: ${socketURL}\n - PATH: ${subPath}`, logStyle);
 });

socketConnection.on('disconnect', () => {
   const logStyle = `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`;
   console.log(`%c[disconnect] | Socket Disconnected`, logStyle);
});

window._socket_ = socketConnection;

export const SocketContext = createContext();
