import { Menu, MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';
import { useState } from 'react';
import { Button } from '@rmwc/button';
import { changeVideoDeviceCall, changeVideoDevicePreview } from 'js/webex-controls';
import { Tooltip } from '@rmwc/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { assignVideoDevice } from 'app/user';
import { useEffect } from 'react';


export default function VideoSelection(props) {
   const dispatch = useDispatch()
   const [open, setOpen] = useState(false);
   const [devices, setDevices] = useState([])
   const user = useSelector(state => state.user)
   const query = new URLSearchParams(window.location.search)
   const videoSource = query.get('videoSource')

   const handleAction = (index) => {
      if (props.videoPreMuted) {
         props.setVideoPreMuted(!props.videoPreMuted)
      }
      if (!props.webex) {
         changeVideoDevicePreview(devices[index].deviceId, user.audioDevice)
      }
      else changeVideoDeviceCall(devices[index].deviceId)
      dispatch(assignVideoDevice({ label: devices[index].label, id: devices[index].deviceId }))
      updateSelection()
   }

   function updateSelection() {
      const videoDevices = user.availableDevices.filter((device) => device.kind === 'videoinput' && device.label);

      setDevices(videoDevices)
   }

   useEffect(() => {
      if (user.availableDevices.length > 0) {
         updateSelection()
      }
   }, [user.availableDevices])

   // at first we might only have a device label, we want both label and ID
   useEffect(() => {
      devices.forEach((device) => {
         if (device.label === user.videoDevice.label) {
            dispatch(assignVideoDevice({ label: device.label, id: device.deviceId }))
         }
      })
   }, [devices])

   // this is for highlighting the correct device once in-call
   useEffect(() => {
      if (videoSource !== null) {
         let videoDevices = []
         user.availableDevices.forEach((device) => {
            if (device.kind === 'videoinput' && device.label) {
               if (device.deviceId === videoSource) {
                  dispatch(assignVideoDevice({ label: device.label, id: device.deviceId }))
               }
               videoDevices.push(device)
            }
         })
         setDevices(videoDevices)
      }
   }, [user.availableDevices])

   if (devices.length < 1) {
      return (
         <Tooltip content="Camera Select">
            <Button aria-label="More options" className="btnRight" alt="Select Video Device" icon="expand_more" disabled />
         </Tooltip>
      );
   }

   return (
      <MenuSurfaceAnchor>
         <Menu
            focusOnOpen={false}
            open={open}
            onSelect={evt => handleAction(evt.detail.index)}
            onClose={() => setOpen(false)}
            anchorCorner='bottomRight'
            className='audioVideo'
         >
            {
               devices.map((device) => (
                  <MenuItem className="device-item" selected={device.label === user.videoDevice.label} key={device.deviceId}>{device.label}</MenuItem>
               ))
            }
         </Menu>
         <Tooltip content="Camera Select">
            <Button aria-label="More options" className="btnRight" alt="Select Video Device" icon="expand_more" onClick={() => setOpen(!open)} />
         </Tooltip>
      </MenuSurfaceAnchor>
   );
}