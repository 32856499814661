import { useDispatch, useSelector } from "react-redux"
import { create } from "./cursors";
import SvgArrow from "./cursors/SvgArrow";

const defaultSize = 30;

export default function CursorSVG(props = {}) {
   const ENV = useSelector(state => state.env);
   const { CURSOR_ICON, CURSOR_SIZE, CURSOR_WIDTH, CURSOR_HEIGHT } = ENV;
   const color = props.color || props.fill || '#000000';
   const icon = (props.icon || props.type || CURSOR_ICON || 'arrow').toLowerCase();
   const width = props.width || parseSize(CURSOR_WIDTH) || parseSize(CURSOR_SIZE) || defaultSize;
   const height = props.height || parseSize(CURSOR_HEIGHT) || parseSize(CURSOR_SIZE) || defaultSize;

   const cursorEl = create({
      icon,
      width,
      height,
      color,
   });

   console.warn('cursorEl', cursorEl);

   if (icon === 'arrow') return <SvgArrow {...props} />;
   // if (icon === 'arrow2') return <SvgArrow2 {...props} />;
   // if (icon === 'pointer') return <SvgPointer {...props} />;

   // Fallback to arrow.
   return <SvgArrow {...props} />;

}

function parseSize(val) {
   if (!val) return; // Includes 0 (null).
   const num = +val;
   if (isNaN(num)) return;
   if (num < 1) return;
   return num;
}