import { SocketContext } from "context/socket"
import { useState, useEffect, useContext } from "react"
import { CSS_COLOR_NAMES } from 'aesthetic/css_colors'
import { useDispatch, useSelector } from "react-redux"
import { assignCursors } from "app/participants"
import { assignCursorColor } from "app/user"
import { useRef } from "react"
import CursorSVG from "./CursorSVG"

export default function Cursor() {
   const ENV = useSelector(state => state.env);
   const CURSOR_DEBUG = ENV?.CURSOR_DEBUG;
   const CURSOR_TRACK_TIME = ENV?.CURSOR_TRACK_TIME;

   const socket = useContext(SocketContext)
   const cursorMovement = 'cursor-movement'
   const user = useSelector(state => state.user)
   const participants = useSelector(state => state.participants.participants)
   const cursors = useSelector(state => state.participants.cursors)
   const cursorsRef = useRef(cursors)
   const dispatch = useDispatch()
   const [allCursors, setAllCursors] = useState([])


   // only want initial state, will never reassign
   const [cursorColor,] = useState(CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)])

   const myColor = user.cursorColor;

   const broadcastPosition = (e) => {
      const { clientX, clientY } = e
      let propX = clientX === 0 ? 0 : clientX / window.innerWidth
      let propY = clientY === 0 ? 0 : clientY / window.innerHeight
      // socket.emit(cursorMovement, { id: user.webex.id, x: propX, y: propY, color: cursorColor })
      socket.emit(cursorMovement, { id: user._id, x: propX, y: propY, color: myColor || cursorColor })
   }

   const vncBroadcastPosition = (e) => {
      const { cursorX, cursorY } = e.detail
      // socket.emit(cursorMovement, { id: user.webex.id, x: cursorX, y: cursorY, color: cursorColor })
      socket.emit(cursorMovement, { id: user._id, x: cursorX, y: cursorY, color: myColor || cursorColor })
   }

   const debugTime = (against, compare) => {
      if (!compare || !compare.time || !compare.name) return;
      const { time, name, } = compare;

      const convertFormat = (time) => {
         const now = new Date(time); //?
         const minutesNow = now.getMinutes().toString().padStart(2, '0') //?;
         const secondsNow = now.getSeconds().toString().padStart(2, '0'); //?
         const formatted = `${minutesNow}:${secondsNow}`;
         return formatted
      }

      console.log();
      console.group(name);
      console.warn(`${convertFormat(against)} - NOW`);
      console.warn(`${convertFormat(time)} - ${name}`);
      console.warn(`${convertFormat(against - time)} - DIFF`);
      console.groupEnd(name);
      console.log();
   }

   const renderCursors = (data) => {
      // console.log("renderCursors()", data);
      let list = []
      // const time = new Date().getTime()
      const time = Date.now();

      for (let pId in participants) {
         const participant = participants[pId];
         // console.log(participant);
         const participantExists = participant.id === data.id;
         const cursorIsNotMine = participant.id !== user._id;
         const participantTime = participant.time;
         const participantTimeIsRecent = CURSOR_TRACK_TIME ? participantTime >= time - 10000 : true;

         (CURSOR_DEBUG) && (debugTime(time, { name: 'PARTICIPANT', time: participantTime }));
         // console.log({ participantExists, cursorIsNotMine, participantTimeIsRecent });

         // if (participants[pId].id === data.id && participants[pId].id !== user._id && participants[pId].time >= time - 10000) {
         if (participantExists && cursorIsNotMine && participantTimeIsRecent) {
            // console.log('--- ALL SUFFICE ---');
            let cursor = {
               name: participants[pId].name,
               id: participants[pId].id,
               cursorX: data.x,
               cursorY: data.y,
               color: data.color,
               time: participants[pId].time,
               show: true,
            }
            // console.info('assignCursors()', cursor);
            dispatch(assignCursors(cursor))

            const cursorExists = !!cursorsRef.current[pId];
            const cursorTime = cursorsRef.current[pId].time
            const cursorTimeIsRecent = CURSOR_TRACK_TIME ? cursorTime >= time - 10000 : true;

            (CURSOR_DEBUG) && (debugTime(time, { name: 'CURSOR', time: cursorTime }));

            // if (cursorsRef.current[pId] !== undefined && cursorsRef.current[pId].time >= time - 10000) {
            if (cursorExists && cursorTimeIsRecent) {
               const left = cursorsRef.current[pId].cursorX * window.innerWidth;
               const top = cursorsRef.current[pId].cursorY * window.innerHeight;

               const cursorObj = cursorsRef.current[pId];
               const { color, name, id } = cursorObj;
               const nameTrimmed = name.split(' (guest)')[0];

               list.push(
                  <div
                     className="cursor-wrapper"
                     key={id}
                     style={{
                        visibility: 'visible',
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 95,
                        pointerEvents: 'none',
                     }}
                  >
                     <div
                        id={`cursor_${id}`}
                        style={{
                           position: 'absolute',
                           pointerEvents: 'none',
                           left,
                           top,
                           // transform: 'translate(-50%, -50%)'
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexDirection: 'row',
                           userSelect: 'none',
                           visibility: 'visible',
                        }}
                     >
                        <CursorSVG
                           color={color}
                           type="arrow"
                           size={30}
                        />
                        <div
                           className="cursor-names"
                           style={{
                              fontFamily: "'Poppins', sans-serif",
                              whiteSpace: 'nowrap',
                              maxWidth: 100,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              // borderRadius: '0.25rem',
                              borderRadius: '5px',
                              padding: '0.2rem',
                              fontSize: 12,
                              backgroundColor: color,
                              bottom: 0,
                           }}
                        >
                           {nameTrimmed}
                        </div>
                        {/* <CursorPopper
                           icon="arrow"
                           color={color}
                           size={30}
                           name={nameTrimmed}
                        /> */}

                     </div>
                  </div>
               );

               // list.push(
               //    <div
               //       className="cursorWrapper"
               //       key={participants[pId].id}
               //       // style={{
               //       //    display: cursor.show === false ? 'none' : 'block',
               //       // }}
               //       style={{
               //          visibility: 'visible',
               //       }}
               //    >
               //       <div
               //          id={`cursor_${pId}`}
               //          className="flex-container-row center"
               //          style={{
               //             position: 'absolute',
               //             pointerEvents: 'none',
               //             left,
               //             top,
               //             transform: 'translate(-50%, -50%)'
               //          }}
               //       >
               //          <CursorSVG
               //             color={cursorsRef.current[pId].color}
               //             type="arrow"
               //             size={30}
               //          />
               //       </div>
               //       <div
               //          id={`cursor_${pId}`}
               //          className="flex-container-row center"
               //          style={{
               //             position: 'absolute',
               //             pointerEvents: 'none',
               //             left,
               //             top,
               //             transform: 'translate(-50%, -50%)'
               //          }}
               //       >
               //          {cursorsRef.current[pId].name !== undefined &&
               //             <p className="cursor-name" style={{ backgroundColor: cursorsRef.current[pId].color }}>
               //                {cursorsRef.current[pId].name.split(' (guest)')[0]}
               //             </p>
               //          }
               //       </div>
               //    </div>
               // )

            }
         }
      }
      // console.log({ list, participants, cursors });
      setAllCursors(list)
   }

   useEffect(() => {
      cursorsRef.current = cursors
   })

   useEffect(() => {
      document.querySelector('.largeTileClass').addEventListener('mousemove', broadcastPosition, false)
      document.addEventListener('vncmousemove', vncBroadcastPosition, false)
      return () => {
         let el = document.querySelector('.largeTileClass')
         if (el) el.removeEventListener('mousemove', broadcastPosition)
         document.removeEventListener('vncmousemove', vncBroadcastPosition)
      }
   }, [user])

   useEffect(() => {
      dispatch(assignCursorColor(cursorColor))
   }, [])

   useEffect(() => {
      const handleCursorMovement = (message) => {
         // console.log('handleCursorMovement()', message);
         renderCursors(message.message)
      }
      socket.on(cursorMovement, handleCursorMovement)

      return () => {
         socket.off(cursorMovement, handleCursorMovement)
      }
   }, [participants])

   return (
      <>
         {allCursors}
      </>
   )
}