import VideoPreview from 'components/VideoPreview';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import PreviewHeader from 'aesthetic/PreviewHeader';
import { useDispatch, useSelector } from 'react-redux';
import { updateAudioPreMuted, updateVideoPreMuted } from "app/user";

export default function Preview(props) {
   const dispatch = useDispatch();
   const params = useParams();
   // const [videoPreMuted, setVideoPreMuted] = useState(false);
   // const [audioPreMuted, setAudioPreMuted] = useState(false);
   const audioPreMuted = useSelector(state => state.user.audioPreMuted);
   const videoPreMuted = useSelector(state => state.user.videoPreMuted);
   const setAudioPreMuted = (val) => dispatch(updateAudioPreMuted(val))
   const setVideoPreMuted = (val) => dispatch(updateVideoPreMuted(val))

   return (
      <>
         <div className="page-bg" />
         <div className="animation-wrapper">
            <div className="particle particle-1"></div>
            <div className="particle particle-2"></div>
            <div className="particle particle-3"></div>
            <div className="particle particle-4"></div>
         </div>
         <div className="flex-container-column" style={{ height: '100%' }}>
            <PreviewHeader roomId={params.roomId} />
            <VideoPreview isUserLogin={true} roomId={params.roomId} setAudioPreMuted={setAudioPreMuted} setVideoPreMuted={setVideoPreMuted} audioPreMuted={audioPreMuted} videoPreMuted={videoPreMuted} videoSize={props.videoSize} validInput={true} />
         </div>
      </>
   );
}
