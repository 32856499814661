// export * as arrow from './arrow';
// export * as arrow2 from './arrow2';
// export * as pointer from './pointer';
import arrow from './arrow';
import arrow2 from './arrow2';
import pointer from './pointer';

const CURSORS = {
   arrow,
   arrow2,
   pointer,
};

export function _setAttrs(el, attr, val) {
   if (!el || !attr || !val) return;
   const isElement = el instanceof HTMLElement;
   if (!isElement) return;

   if (el.hasAttribute(attr)) {
      el.setAttribute(attr, val);
   }

   if (el.hasChildNodes()) {
      el.childNodes.forEach((child) => {
         _setAttrs(child, attr, val);
      });
   }
}

export function _parseEl(string) {
   if (!string) return;
   const parse = new DOMParser().parseFromString(string, 'text/html');
   const el = parse.body.firstChild;

   return el;
}

export function create(param) {
   const fill = param?.fill || param?.color;
   const width = param?.width || param?.size;
   const height = param?.height || param?.size;

   if (!fill || !width || !height) {
      console.error('Missing required param');
      return;
   }
   const icon = param.icon || window?._env_.REACT_APP_CURSOR_ICON || window?._env_.CURSOR_ICON || 'arrow';

   const cursorString = CURSORS[icon];
   const el = _parseEl(cursorString);

   _setAttrs(el, 'fill', fill);
   _setAttrs(el, 'width', width);
   _setAttrs(el, 'height', height);

   console.warn('cursor el', el);

   return el;
}
