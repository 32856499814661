import { Dialog } from "@rmwc/dialog";
import { useRoom } from "net/rooms";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getMeetingMembers, muteUser, removeUser } from 'js/webex-controls';
import { Button } from "@rmwc/button";
import { Fab } from "@rmwc/fab";
import { MenuItem, MenuSurfaceAnchor, Menu } from "@rmwc/menu";
import { SocketContext } from "context/socket";
import { Divider } from "@react-spectrum/divider";
import { useDispatch, useSelector, userList } from "react-redux";
import { assignWebexSelf, updateUserList, userSlice } from "app/user";
import { React, useRef } from "react";
import { assignSharing } from "app/meeting";
import Avatar from 'react-avatar';
import './Modals.css'
import { updateRoom } from "app/room";

export default function ParticipantsInfoModal(props) {
   const socket = useContext(SocketContext)
   const params = useParams()
   const dispatch = useDispatch()
   const user = useSelector(state => state.user)
   const userList = user.userList;
   const participants = useSelector(state => state.participants.participants)
   const cursors = useSelector(state => state.participants.cursors)
   const room = useRoom(params.roomId);
   if (room.data && !room.isError) {
      dispatch(updateRoom(room.data));
   }
   const [spaceName, setSpaceName] = useState('')
   const [copyText, setCopyText] = useState('Copy Link')
   const setWebexSelf = 'set-webex-self'
   const checkParticipants = 'check-participants'
   const [allParticipants, setAllParticipants] = useState([])
   const participantsRef = useRef(allParticipants)
   const determineSharing = () => {
      // let membersList = getMeetingMembers()
      let membersList = []
      for (let member in membersList) {
         if (membersList[member].isContentSharing) {
            dispatch(assignSharing(membersList[member].name))
            props.setUserSharing(membersList[member].name)
         }

         if (membersList[member].isSelf) {
            dispatch(assignWebexSelf(membersList[member]))
         }
      }
   }


   const renderParticipants = () => {
      let list = []
      let index = 1
      for (let pId in participants) {
         if (participants[pId].status === 'IN_MEETING') {
            let color = cursors[pId] !== undefined ? cursors[pId].color : 'black'
            list.push(
               <ParticipantListItem
                  key={participants[pId].id}
                  participant={participants[pId]}
                  index={index}
                  accessLevel={props.accessLevel}
                  setParticipantsOpen={props.setParticipantsOpen}
                  socket={socket}
                  cursorColor={color}
               >
                  <Avatar
                     name={participants[pId].name}
                     initials={participants[pId].initials}
                     size="40"
                     round={true}
                     textSizeRatio={1.00}
                     style={{ marginRight: "10px" }}
                     color={color}
                  />
                  {participants[pId].name}
               </ParticipantListItem>
            )
            index += 1
         }
      }
      setAllParticipants(list)
      return
   }


   const [email, setEmail] = useState("")

   const onChange = (event) => {
      setEmail(event.target.value);
   }

   const onSearch = (searchedEmail) => {
      setEmail(searchedEmail);
      console.log("search", searchedEmail)
   }


   const handleSubmit = (event) => {
      event.preventDefault();
      // console.log(`Submitted email: ${email}`);
      // setEmail(event);
   };

   const handleClose = () => {
      props.setParticipantsOpen(false)
   }
   const handleOpen = () => {
      // buildCurrentMembers()
      determineSharing()
   }

   useEffect(() => {
      participantsRef.current = allParticipants
   })

   useEffect(() => {
      if (room.data) {
         setSpaceName(room.data.name)
      }
   }, [room.data])

   useEffect(() => {
      determineSharing();
   }, [])

   useEffect(() => {
      renderParticipants()
   }, [participants])
   // initilize members list without having to open modal

   useEffect(() => {
      socket.on(setWebexSelf, () => {
         handleOpen();
         renderParticipants()
      })

      socket.on(checkParticipants, () => {
         // buildCurrentMembers()
         determineSharing()
      })
   }, [])

   return (
      <>
         <Dialog preventOutsideDismiss={false}
            open={props.participantsOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            className="spaceInfo"
            style={{ left: "calc(35.5% - 270px)", top: "calc(49% - 270px)" }}>
            <div className="flex-container-row flex-end headerPadding">
               <button className="tileClose btn" onClick={handleClose}>
                  <span className="material-icons">clear</span>
               </button>
            </div>
            <h1 className="spaceInfoTitle">Participants</h1>
            <div className="flex-container-row detailSection">
               <div className="flex-container-column participantsList">
                  <div className="email-form">
                     <form style={{ paddingTop: "5px" }} onSubmit={handleSubmit}>

                        <div className="email-form-inner">
                           <Fab
                              className="primePillBtn addParticipant"
                              icon="person_add"
                              mini>
                           </Fab>
                           <input
                              type="email"
                              value={email}
                              placeholder="Enter Email Here"
                              style={{ marginLeft: "10px", marginRight: "10px" }}
                              onChange={onChange}
                           />
                           <input
                              type="submit"
                              value="Send"
                              style={{ backgroundColor: "transparent", marginBottom: "10px" }}
                              onClick={() => onSearch(email)}
                           />
                        </div>

                        <div className="dropdown">
                           {user.userList
                              .filter((user) => {
                                 const searchedEmail = email?.toLowerCase();
                                 const emails = user.email?.toLowerCase();
                                 if (!searchedEmail || !emails) return false;
                                 return (searchedEmail &&
                                    emails.startsWith(searchedEmail) &&
                                    emails !== searchedEmail);
                              })
                              .slice(0, 10)
                              .map((user) => (
                                 <div
                                    className="dropdown-row"
                                    onClick={() => onSearch(user.email)}
                                    key={user.email}
                                    style={{
                                       textalign: "start",
                                       margin: "2px 0"
                                    }}>
                                    {user.email}
                                    <Divider />
                                 </div>
                              ))}
                        </div>
                     </form>
                  </div>
                  <h3 className="modalSubHeading">In Call</h3>
                  <div className="flex-container-column">
                     <ParticipantListItem
                        key={user._id}
                        participant={{ name: user.name }}
                        index={0}
                        accessLevel={props.accessLevel}
                        setParticipantsOpen={props.setParticipantsOpen}
                        socket={socket}
                        cursorColor={user.cursorColor}
                     />
                     {allParticipants}
                  </div>
               </div>
            </div>
         </Dialog>
      </>
   )
}

export function ParticipantListItem(props) {
   const userKick = 'user-kick'
   const [open, setOpen] = useState(false);
   const handleSkittleClick = () => {
      setOpen(true)
   }
   const handleSelect = (e) => {
      switch (e.target.index) {
         case 0:
            muteUser(props.participant.id)
            props.setParticipantsOpen(false)
            return
         case 1:
            removeUser(props.participant.id)
            props.socket.emit(userKick, { 'id': props.participant.id })
            props.setParticipantsOpen(false)
            return
         default:
            return
      }
   }

   return (
      <>
         <div className="flex-container-row participantItem">
            <div className="flex-container-row userGrouping">
               <Avatar
                  name={props.participant.name}
                  initials={props.participant.initials}
                  size="60"
                  round={true}
                  textSizeRatio={1.00}
                  style={{ marginRight: "10px" }}
                  color={props.cursorColor}
               />
               <svg width="10" height="20" style={{ fill: props.cursorColor, stroke: props.cursorColor, borderRadius: '0.5rem' }}>
                  <rect width="20" height="20" />
               </svg>
               <h5 className="participantName" >{props.participant.name}</h5>
            </div>
            <div className="flex-container-row actionGrouping">
               {props.participant.isAudioMuted &&
                  <span className="material-icons md-18" style={{ color: '#B00020' }}>mic_off</span>
               }
               {
                  // props.accessLevel === 'owner' &&
                  //   <MenuSurfaceAnchor>
                  //     <Menu
                  //       open={open}
                  //       onSelect={handleSelect}
                  //       onClose={() => setOpen(false)}
                  //       anchorCorner='topStart'
                  //       className='participantMore'>
                  //       <MenuItem key="mute">Mute User</MenuItem>
                  //       <MenuItem key="kick">Kick User from Space</MenuItem>
                  //     </Menu>
                  //     <Fab onClick={handleSkittleClick}
                  //       className="primePillBtn participantMore"
                  //       icon="more_horiz"
                  //       mini>
                  //     </Fab>
                  //   </MenuSurfaceAnchor>
               }
            </div>
         </div>
      </>
   )
}