import Loading from "aesthetic/Loading";
import PreviewHeader from "aesthetic/PreviewHeader";
import VideoPreview from "components/VideoPreview";
import { useState } from "react";
import { useParams } from "react-router";
import './Pages.css'
import { useSelector, useDispatch } from 'react-redux';
import { updateAudioPreMuted, updateVideoPreMuted } from "app/user";

export default function GuestPreview(props) {
   const dispatch = useDispatch();
   const ENV = useSelector(state => state.env);
   const params = useParams();
   const [loading, setLoading] = useState(false)
   const audioPreMuted = useSelector(state => state.user.audioPreMuted);
   const videoPreMuted = useSelector(state => state.user.videoPreMuted);
   const setAudioPreMuted = (val) => dispatch(updateAudioPreMuted(val))
   const setVideoPreMuted = (val) => dispatch(updateVideoPreMuted(val))
   // const [videoPreMuted, setVideoPreMuted] = useState(false);
   // const [audioPreMuted, setAudioPreMuted] = useState(false);
   const [admitMessage, setAdmitMessage] = useState('Waiting to be admitted')

   document.title = `3Data | ${ENV.VERSION}`;

   if (loading) {
      return (
         <Loading message={admitMessage} />
      )
   } else {
      return (
         <>
            <div className="page-bg" />
            <div className="animation-wrapper">
               <div className="particle particle-1"></div>
               <div className="particle particle-2"></div>
               <div className="particle particle-3"></div>
               <div className="particle particle-4"></div>
            </div>
            <div className="fullVheight flex-container-column">
               <PreviewHeader roomId={params.roomId} />
               <div className="fullWidth">
                  <VideoPreview
                     isUserLogin={false}
                     roomId={params.roomId}
                     setAudioPreMuted={setAudioPreMuted}
                     setVideoPreMuted={setVideoPreMuted}
                     audioPreMuted={audioPreMuted}
                     videoPreMuted={videoPreMuted}
                     videoSize={props.videoSize}
                     validInput={true}
                     setLoading={setLoading}
                     setAdmitMessage={setAdmitMessage}
                  />
                  {/* <div className="flex-container-column center sign-in">
                     <p>Already have an account?</p>
                     <a href={`${window.location.origin}/?redirectTo=${window.location.href}`}>Sign in here</a>
                  </div> */}
               </div>
            </div>
         </>
      )
   }
}
