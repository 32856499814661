import { createSlice } from '@reduxjs/toolkit';

export const participantsSlice = createSlice({
   name: 'participants',
   initialState: {
      participants: {},
      cursors: {},
   },
   reducers: {
      assignParticipants: (state, action) => {
         return {
            ...state,
            participants: {
               ...state.participants,
               [action.payload.id]: { ...action.payload },
            },
         };
      },
      assignCursors: (state, action) => {
         return {
            ...state,
            cursors: {
               ...state.cursors,
               [action.payload.id]: { ...action.payload },
            },
         };
      },
      modifyCursor: (state, action) => {
         const payload = action.payload;
         const participantId = payload?.id || payload?._id;
         if (!participantId) return state;

         return {
            ...state,
            cursors: {
               ...state.cursors,
               [participantId]: {
                  ...state.cursors[participantId],
                  ...payload,
               },
            },
         };
      },
   },
});

export const { assignParticipants, assignCursors, modifyCursor } = participantsSlice.actions;
export default participantsSlice.reducer;
